import {ClientTags, SoftwareTags} from "./TagsEnums.ts";
import ProjectImageTemplate from "./ProjectImageTemplate";

const Description = () =>
{
    return (
        <div>
            <ProjectImageTemplate
                image={"https://media.licdn.com/dms/image/v2/D5603AQGOTnhSjr5cOg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1719326334120?e=1738195200&v=beta&t=5pZJinz7GzcTlJhJ93kPZhuhXjvBvfV1O-Jgw_p38KQ"}
                onClick={() => window.open("https://www.linkedin.com/in/shubhgawhade/", "_blank")}
            />
            <h1 className="highlightText">Hi, I’m Shubh Gawhade, a developer with a passion for product design and
                problem-solving with 2 years of industry experience. Through my freelance work and managing my own studio, I have honed my analytical
                thinking and leadership skills. I have successfully guided small teams through development cycles and
                collaborated with clients like Mythos Labs, YouTube and SSRC university, which has broadened my
                perspective. I am always on the lookout for opportunities to grow, learn, and succeed, even if it means
                facing failures along the way.</h1>
        </div>
    );
}

const AboutMe =
    {
        backdropColor: 'rgba(0,0,0,0.75)',
        backgroundColor: '#000000',
        boxShadowColor: '#000000',
        name: 'ABOUT ME',
        imageURL: '',
        // imageURL: 'https://media.licdn.com/dms/image/v2/D5603AQGOTnhSjr5cOg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1719326334120?e=1738195200&v=beta&t=5pZJinz7GzcTlJhJ93kPZhuhXjvBvfV1O-Jgw_p38KQ',
        // videoURL: 'https://dms.licdn.com/playlist/vid/v2/D4D05AQHF6nbwsucQDg/mp4-640p-30fp-crf28/mp4-640p-30fp-crf28/0/1696563361642?e=2147483647&v=beta&t=u1qUiAhJzcrAg6YmU6EQbCKyGxsIPG1rsU71EbPUdCY&vq=hd1080',
        softwareTags:
            [
                // SoftwareTags.React,
                SoftwareTags.Javascript,
                SoftwareTags.Typescript,
                SoftwareTags.SocketIO,
                // SoftwareTags.Node,
                SoftwareTags.Unity,
                SoftwareTags.CSharp,
                SoftwareTags.SQL,
                SoftwareTags.AWS,
                SoftwareTags.Docker,
                SoftwareTags.Redis,
                SoftwareTags.CPlusPlus,
                SoftwareTags.Python,
            ],
        clientTags:
            [
                // SoftwareTags.Youtube,
            ],
        externalLinks:
            [
                SoftwareTags.LinkedIn,
                SoftwareTags.ItchIo,
                SoftwareTags.Github,
                SoftwareTags.oofDa,
                SoftwareTags.Gmail,
                SoftwareTags.Portfolio
            ],
        body: Description,
    };

export {AboutMe};