import {SoftwareTags} from "../TagsEnums.ts";

const Description = () =>
{
    return (
        <div>
            <p>This is the project description.</p>
        </div>
    );
}

const SatyarakshakProject =
    {
        backdropColor: 'rgba(0,0,0,0.75)',
        backgroundColor: '#000000',
        boxShadowColor: '#000000',
        name: 'Satyarakshak',
        imageURL: 'https://cdn.pixabay.com/photo/2022/05/17/02/11/fractal-7201481_1280.jpg',
        videoURL: 'https://dms.licdn.com/playlist/vid/v2/D4D05AQHF6nbwsucQDg/mp4-640p-30fp-crf28/mp4-640p-30fp-crf28/0/1696563361642?e=2147483647&v=beta&t=u1qUiAhJzcrAg6YmU6EQbCKyGxsIPG1rsU71EbPUdCY&vq=hd1080',
        softwareTags:
            [
                SoftwareTags.Unity,
                SoftwareTags.CSharp,
            ],
        clientTags:
            [
                SoftwareTags.Youtube,
            ],
        body: Description,
    };

export {SatyarakshakProject};