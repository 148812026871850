import ProjectCard from "./ProjectCard";
import React, {useEffect, useState} from "react";
import {OWRProject} from "./projects/OWRProject";
import {SatyarakshakProject} from "./projects/SatyarakshakProject";
import {ProjectStrawberry} from "./projects/ProjectStrawberry";

const Portfolio = () =>
{
    function setVh() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    useEffect(() =>
    {
        // Initial call
        setVh();

        // Update on resize
        window.addEventListener('resize', setVh);
    },[]);

    // Create different projects which have title, videoUrl, body, softwareTags, clientTags properties
    // store the return value for the project in a variable and pass it to the DescriptionCard component
    const projects =
        [
            OWRProject,
            // SatyarakshakProject,
            ProjectStrawberry,
            // OWRProject,
            // SatyarakshakProject,
        ];


    const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

    useEffect(() =>
    {
        if(currentProjectIndex > projects.length - 1)
        {
            setCurrentProjectIndex(0);
        }

        const handleWheel = (event) =>
        {
            if(projects[currentProjectIndex].showDescription) return;

            const delta = event.deltaY;
            const newIndex = currentProjectIndex + (delta > 0 ? 1 : -1);
            setCurrentProjectIndex(Math.max(0, Math.min(newIndex, projects.length - 1)));
        };

        let lastTouchY = null;
        let touchMoveTimeout = null;

        const handleTouchMove = (event) =>
        {
            if(projects[currentProjectIndex].showDescription) return;

            const touch = event.touches[0];
            if (lastTouchY === null)
            {
                lastTouchY = touch.clientY;
                return;
            }
            const delta = touch.clientY - lastTouchY;
            lastTouchY = touch.clientY;

            // console.log(delta);
            if(Math.abs(delta) < 3)
            {
                lastTouchY = null;
                return;
            }

            clearTimeout(touchMoveTimeout);
            touchMoveTimeout = setTimeout(() =>
            {
                const newIndex = currentProjectIndex + (delta < 0 ? 1 : -1);
                setCurrentProjectIndex(Math.max(0, Math.min(newIndex, projects.length - 1)));
                lastTouchY = null;
            }, 100);
        };

        window.addEventListener('wheel', handleWheel, { passive: true });
        window.addEventListener('touchmove', handleTouchMove, { passive: true });

        return () =>
        {
            window.removeEventListener('wheel', handleWheel);
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, [currentProjectIndex, projects.length]);

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div
                style={{opacity: currentProjectIndex > 0 ? 0 : 0.75, transition: 'opacity 0.5s'}}
                className="fixed-header">
                PROJECTS
            </div>
            <div>
                {projects.map((project, index) => (
                    project.showDescription = false,
                        <ProjectCard key={index} index={index} project={project}
                                     currentProjectIndex={currentProjectIndex}
                                     setCurrentProjectIndex={setCurrentProjectIndex}/>
                ))}
            </div>
        </div>
    )
}

export default Portfolio;