import React, {useEffect, useState} from "react";
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import DescriptionCard from "./DescriptionCard";

const ProjectCard = ({index, project, currentProjectIndex, setCurrentProjectIndex}) =>
{
    const [isPlaying, setIsPlaying] = useState(false);
    // const [showDescription, setShowDescription] = useState(false);

    // console.log(project);
    useEffect(() =>
    {
        if(currentProjectIndex === index || currentProjectIndex === index + 1 || currentProjectIndex === index - 1)
        {
            setIsPlaying(true);
        }
        else
        {
            setIsPlaying(false);
        }

        project.showDescription = false;
        // setShowDescription(project.showDescription);

    }, [index, currentProjectIndex]);

    const handleCloseDescription = () =>
    {
        setCurrentProjectIndex(currentProjectIndex + 1);

        project.showDescription = false;
        // setShowDescription(false);
        setIsPlaying(true);
        // console.log(showDescription);
    };

    const maxTranslateY = 0;

    // Calculate the translateY value
    const translateY = Math.min((-currentProjectIndex * 100), maxTranslateY);

    //TODO: Check the video source, If it is YT, use iframe, else use video tag
    return (
        <div>
            <div className="Container" style={{ transform: `translateY(${translateY}%)`, position: 'sticky', top: '0', zIndex: currentProjectIndex }}>
            <AspectRatio.Root className="projectYTVideo" style={{boxShadow: "0 0 0"}} ratio={16 / 9} >
                <div className="overlay" onClick={() =>
                {
                    setIsPlaying(!isPlaying);
                    project.showDescription = true;
                    // setShowDescription(project.showDescription);
                }}>
                    <div className="ProjectTitle">
                        {project.name}
                    </div>
                </div>

                {
                    isPlaying && project.videoURL && project.videoURL.includes("https://www.youtube.com") &&

                    <iframe className="iframe"
                    // src={project.videoURL}
                    src={project.videoURL + "&autoplay=1&mute=1&loop=1&controls=0&modestbranding=0&rel=0&playsinline=1&enablejsapi=1"}
                    // src="https://www.youtube.com/embed/-M7cxTxlAkw?vq=hd1080&si=HGHNFX9mNTSw1bni&autoplay=1&mute=1&loop=1&controls=0&color=white&modestbranding=0&rel=0&playsinline=1&enablejsapi=1&playlist=-M7cxTxlAkw"
                    title="One-Way Ride Gameplay" frameBorder="0"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    ></iframe>
                }

                {
                    isPlaying && project.videoURL && !project.videoURL.includes("https://www.youtube.com") &&

                    <video autoPlay muted loop controls={false}
                           style={{width: '100%', height: '99.9%', objectFit: 'cover'}}>
                        <source
                                src={project.videoURL + "&autoplay=1&mute=1&loop=1&controls=0&modestbranding=0&rel=0&playsinline=1&enablejsapi=1"}
                                type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                }

                {
                    isPlaying && !project.videoURL &&
                    <div>
                        <img src={project.imageURL} alt={project.name}
                             style={{width: '100%', objectFit: "cover", overflow: "hidden"}}/>
                    </div>
                }
            </AspectRatio.Root>
            </div>
            {project.showDescription && (
                <DescriptionCard project={project} onClose={handleCloseDescription} />
            )}
        </div>
    );
}

export default ProjectCard;