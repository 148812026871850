import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import {Theme} from "@radix-ui/themes";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Theme className="theme" >
          <BrowserRouter>
          <App />
          </BrowserRouter>
      </Theme>
  </React.StrictMode>
);