import {SoftwareTags} from "../TagsEnums.ts";
import React from "react";

const Description = () =>
{
    return (
        <div style={{margin: "0 auto 20px auto", width: "80%"}}>

            <br/><br/>

            <h1 className="text">
                I was asked to assist in the development of an innovative University Metaverse Platform that would
                be recognized by the university for giving a completely interactive virtual campus tour of SRCC.
                This platform was designed to host interactive events, competitions, fests, and more, creating a dynamic
                virtual universe for universities, starting with SRCC.
                <br/><br/>
                As the Project Lead Developer, I was responsible for creating and managing CI/CD pipelines, setting up
                version control, developing game mechanics, optimizing performance, and consulting on product design to
                ensure it was production-ready. I collaborated with a team of 2 developers and 1 artist to create the
                first demo, which was successfully announced with an engaging trailer.
            </h1>

            <br/>

            <div className="externalLinkButton"
                 style={{
                     padding: "10px 10px",
                     backgroundColor: 'var(--buttonColour)'
                 }} onClick={() => {
                window.open('https://www.linkedin.com/feed/update/urn:li:activity:7115902599135948800/', '_blank');
            }}>
                <div style={{fontSize: "1em"}}>
                    Announcement Post
                </div>
            </div>

            <br/><br/>

            <h1 className="text">
                After nine months of intensive development, we successfully launched the first demo. Despite having to
                step away due to external commitments, my contributions laid a solid foundation for the project’s
                ongoing success. This experience showcased my leadership, technical skills, and ability to drive
                innovative projects from concept to launch.
            </h1>

            <br/><br/>

            <video loop controls={true}
                   style={{
                       width: '100%',
                       height: '99.9%',
                       objectFit: 'cover',
                       borderRadius: "8px",
                       boxShadow: "0 0px 10px rgba(0, 0, 0, 1)"
                   }}>
                <source
                    src="https://dms.licdn.com/playlist/vid/v2/D4D05AQHF6nbwsucQDg/mp4-640p-30fp-crf28/mp4-640p-30fp-crf28/0/1696563361642?e=2147483647&v=beta&t=u1qUiAhJzcrAg6YmU6EQbCKyGxsIPG1rsU71EbPUdCY&vq=hd1080"
                    type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
            <div className="videoDescriptionText">
                Project Strawberry Trailer
            </div>

            <div style={{marginTop: "2%"}}>
                <h1 className="subHeading">Play the Prototype</h1>
                <iframe title="Itch io" frameBorder="0"
                        src="https://itch.io/embed/2254668?linkback=true&dark=true"
                        style={{width: "100%", maxWidth: "860px"}}
                        height="50%"><a href="https://projectstrawberry.itch.io/project-strawberry-desktop">Project
                    Strawberry by
                    shubhgawhade</a></iframe>
            </div>

            {/*<ProjectYTVideoTemplate*/}
            {/*    video="https://dms.licdn.com/playlist/vid/v2/D4D05AQHF6nbwsucQDg/mp4-640p-30fp-crf28/mp4-640p-30fp-crf28/0/1696563361642?e=2147483647&v=beta&t=u1qUiAhJzcrAg6YmU6EQbCKyGxsIPG1rsU71EbPUdCY&vq=hd1080"*/}
            {/*    videoDescription={"Project Strawberry Trailer"}/>*/}
        </div>
    );
}

const ProjectStrawberry =
    {
        backdropColor: 'rgba(0,0,0,0.75)',
        backgroundColor: '#000000',
        boxShadowColor: '#000000',
        name: 'Project Strawberry',
        imageURL: 'https://cdn.pixabay.com/photo/2022/05/17/02/11/fractal-7201481_1280.jpg',
        videoURL: 'https://www.youtube.com/embed/z_ZqL8zqszA?vq=hd1080&playlist=z_ZqL8zqszA',
        videoDescription: "Project Strawberry Gameplay",
        showcaseImages:
            [
                // 'https://lh3.googleusercontent.com/KHfoS1VEN-KKGhDi4Je3fHQ91WDOWT4jkqnEBV24phaU88rScR8k_wsOneTc0_eUZ9JxxYrAbRaOAh2D5WiPIY9MOojM15gDRhf6ZsZ8UArF53Sc61qxNQ7Y6MD7ammOuA=w1280',
                'https://img.itch.zone/aW1nLzEzNTc3MjE4LmdpZg==/original/5aEg6o.gif',
                'https://img.itch.zone/aW1nLzEzNTc3ODY3LmdpZg==/original/R4NhL1.gif',
                'https://img.itch.zone/aW1hZ2UvMjI1NDY2OC8xMzU3ODQyMy5naWY=/794x1000/AkoSgo.gif',
                'https://img.itch.zone/aW1nLzEzNTc3ODg2LmdpZg==/original/yhxaxI.gif',
                'https://img.itch.zone/aW1nLzEzNTc3ODkwLmdpZg==/original/SB114z.gif',
                'https://img.itch.zone/aW1nLzEzNTc3ODk0LmdpZg==/original/Ue%2FIqF.gif',
                'https://img.itch.zone/aW1nLzEzNTc3ODk3LmdpZg==/original/MwH%2BeB.gif',
                'https://img.itch.zone/aW1nLzEzNTc3OTAwLmdpZg==/original/jk631o.gif',
                'https://img.itch.zone/aW1nLzEzNTc3OTAyLmdpZg==/original/e29Nj0.gif',
                'https://img.itch.zone/aW1hZ2UvMjI1NDY2OC8xMzU3ODQ1MS5naWY=/794x1000/JAVHPw.gif',
                'https://img.itch.zone/aW1hZ2UvMjI1NDY2OC8xMzU3ODQ2MS5naWY=/794x1000/05OXQF.gif',
                'https://img.itch.zone/aW1hZ2UvMjI1NDY2OC8xMzU3OTQ2OS5naWY=/794x1000/kDTxfB.gif',
            ],
        altText:
            [
                // 'Image1',
                // 'Image2',
            ],
        softwareTags:
            [
                SoftwareTags.Unity,
                SoftwareTags.CSharp,
                SoftwareTags.Photoshop,
                SoftwareTags.Github,
            ],
        clientTags:
            [
                // SoftwareTags.Youtube,
            ],
        body: Description,
    };

export {ProjectStrawberry};