import {SoftwareTags} from "../TagsEnums.ts";
import React, {useEffect, useRef, useState} from "react";
import ProjectImageTemplate from "../ProjectImageTemplate";
import ProjectYTVideoTemplate from "../ProjectYTVideoTemplate";
import {Octokit} from "https://esm.sh/octokit@2.0.19";
import ShowcaseImagesTemplate from "../ShowcaseImagesTemplate";
import conceptArt from '../Images/OWRProject/ConceptArt.png';

const chapter1Images = require.context('../Images/OWRProject/Chapter1', false, /\.png$/);

const chapter1MappedImages = chapter1Images.keys().reduce((images, path) => {
    const imageName = path.replace('./', '').replace('.png', '');
    images[imageName] = chapter1Images(path);
    return images;
}, []);

const pipelineShowcaseImages = require.context('../Images/OWRProject/Chapter1/PipelineShowcase', false, /\.png$/);

let pipelineShowcaseMappedImages =
{
    Images: pipelineShowcaseImages.keys().reduce((images, path) => {
        const imageName = path.replace('./', '').replace('.png', '');
        images[imageName] = pipelineShowcaseImages(path);
        return images;
    }, []),
    Descriptions: [
            "",
    ]
}

const HDRPURPShowcaseImages = require.context('../Images/OWRProject/Chapter1/HDRPURPShowcase', false, /\.png$/);

let HDRPURPMappedImages =
{
    Images: HDRPURPShowcaseImages.keys().reduce((images, path) => {
        const imageName = path.replace('./', '').replace('.png', '');
        images[imageName] = HDRPURPShowcaseImages(path);
        return images;
    }, []),
    Descriptions: [
        "",
    ]
}

let playerNPCDialoguesMappedImages =
{
    Images: [
        chapter1MappedImages['DialogueSystem1'],
        chapter1MappedImages['DialogueSystem2']
        ],
    Descriptions: [
        "Creating a dialogue system by reading player dialogues from a .txt file which splits the text which is in a new line and finished player dialogue which ends in a semi-colon(;) and testing it using temp files.",
        "Creating a dialogue system by reading player dialogues from a .txt file which splits the text which is in a new line and finished player dialogue which ends in a semi-colon(;) and testing it using temp files."
    ]
}

let dialogueAudioMappedImages =
{
    Images: [
        chapter1MappedImages['DialogueAudio1'],
        chapter1MappedImages['DialogueAudio2']
    ],
    Descriptions: [
        "Updated TextReader.cs script which loads the lines of dialogues in the script and assigns each of them the respective audio files.",
        "Class DialogueAudioMatch which stores each dialogue with its audio file and speaker"
    ]
}

// Chapter 2 Images
const chapter2Images = require.context('../Images/OWRProject/Chapter2', false, /\.png$/);

const chapter2MappedImages = chapter2Images.keys().reduce((images, path) => {
    const imageName = path.replace('./', '').replace('.png', '');
    images[imageName] = chapter2Images(path);
    return images;
}, []);

let explorationLevelsMappedImages =
{
    Images: [
        chapter2MappedImages['ExplorationLevelsCode1'],
        chapter2MappedImages['ExplorationLevelsCode2']
    ],
    Descriptions: [
        "Code to detect when the player enters a level trigger and hides everything above it by detecting collisions between triggers and other objects",
        "Code to fade or show the materials with a speed"
    ]
}

let gunplayMappedImages =
{
    Images: [
        chapter2MappedImages['GunplayCode1'],
        chapter2MappedImages['GunplayCode2'],
        chapter2MappedImages['GunplayCode3']
    ],
    Descriptions: [
        "When the character is in the Gunplay state, it gets the mouse location as the target and shoots",
        "Instantiates a bullet at the Spawn Location and turns it towards the target",
        "Attached to the bullet and moves it forward with a speed"
    ]
}

let shootingMappedImages =
{
    Images: [
        chapter2MappedImages['ShootingCode1'],
        chapter2MappedImages['ShootingCode2']
    ],
    Descriptions: [
        "Code to determine the action when the player clicks on anything during the Gunplay state",
        "Waits for the animation to finish and then shoots using the shooting animation rig activated earlier"
    ]
}

let interactablesMappedImages =
{
    Images: [
        chapter2MappedImages['InteractableCode1'],
        chapter2MappedImages['InteractableCode2'],
        chapter2MappedImages['InteractableCode3']
    ],
    Descriptions: [
        "Converted the interactable to a base class with general functions",
        "Cover Interactables which inherits from Interactables and handles additional functionality for crouching while shooting",
        "Scripted Interactables which inherits from Interactables and handles additional functionality for displaying dialogues and ending it when needed"
    ]
}

let intelligenceSystemMappedImages =
{
    Images: [
        chapter2MappedImages['IntelligenceSystem1'],
        chapter2MappedImages['IntelligenceSystem2'],
        chapter2MappedImages['IntelligenceSystem3'],
        chapter2MappedImages['IntelligenceSystem4']
    ],
    Descriptions: [
        "Hierarchy for Chapter 2 first part interactables",
        "Interaction is not visible as the player has a low intelligence level",
        "Player gains more information thus increasing his intelligence",
        "Player is now able to interact with the Receptionist"
    ]
}

let ragdollPhysicsMappedImages =
{
    Images: [
        chapter2MappedImages['RadollPhysics1'],
        chapter2MappedImages['RadollPhysics2']
    ],
    Descriptions: [
        "Applying forces before enabling ragdoll on the player",
        "Basic Health Manager"
    ]
}

const Description = () => {
    // const [Chapter2, setChapter2] = useState(null);
    const [Chapter2, setChapter2] = useState(null);

    const [commitData, setCommitData] = useState([]);

    const [chapter1Headings, setChapter1Headings] = useState(
        {
            Introduction: false,
            PlayerMovement: false,
            Interactables: false,
            PlayerNPCDialogues: false,
            EventFlow: false,
            AssetPipeline: false,
            DialogueAudio: false,
        });

    const [chapter2Headings, setChapter2Headings] = useState(
        {
            Introduction: false,
            ExporationLevels: false,
            Gunplay: false,
            CrouchingCoverMechanics: false,
            ShootingCoverMechanics: false,
            IntelligenceDrunkennessSystem: false,
            RagdollPhysics: false,

        });

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handleImageChange = (index) => {
        setCurrentImageIndex(index);
    };

    useEffect(() => {
        async function fetchData() {
            const octokit = new Octokit({
                auth: process.env.OWR_TOKEN
            });

            const response = await octokit.request('GET /repos/{owner}/{repo}/commits',
                {
                    owner: 'shubhgawhade',
                    repo: 'Abertay-Hons-Demo-HDRP',
                });

            setCommitData(response.data);
        }

        fetchData();
    }, []);

    const introElements = useRef([]);

    // useEffect(() =>
    // {
    //     introElements.current = [];
    // }, [chapter1Headings, chapter2Headings]);

    useEffect(() =>
    {
        const calculateMaxHeight = () => {
            introElements.current.forEach((element) => {
                if (element) {
                    const maxHeight = element.scrollHeight;
                    element.style.setProperty("--max-height", `${maxHeight}px`);
                }
            });
        };

        window.addEventListener('resize', calculateMaxHeight);


        // Call handleResize initially to set the heights
        const timer = setTimeout(calculateMaxHeight, 100);

        return () => {
            window.removeEventListener('resize', calculateMaxHeight);
            clearTimeout(timer);
        };

    }, [Chapter2, chapter1Headings, currentImageIndex]);

    return (
        <div style={{margin: "0 auto 20px auto", width: "80%"}}>

            <h1 className="highlightText">
                A story driven 3D-Top-Down hybrid game prototype combining 3D
                top-down as a base with multiple genres of play-styles
            </h1>

            <h1 className="text">
                This is a game prototype I've been working on for my final year
                project with Kshitij Khandare working as the Lead Narrative Designer
                and Game designer, Josh Bridges as an Assistant Developer and Level Designer,
                and I will be taking the roles of the Project Manager and Lead Developer to research
                about the methods to implement interactive game-play around a narrative. We aim to deliver
                a polished game prototype.
            </h1>

            <ProjectImageTemplate image={conceptArt}
                                  imageDescription="Concept art for the game"/>

            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                <div className="externalLinkButton" style={{
                    padding: "10px 10px",
                    marginRight: "10%",
                    backgroundColor: 'var(--buttonColour)'
                }} onClick={() => {
                    window.open('https://drive.google.com/file/d/18G0_9fyFGpGbkAQu6TFijp1GNKecW88z/view', '_blank');
                }}>
                    <div style={{fontSize: "1em"}}>
                        Elevator Pitch
                    </div>
                </div>

                <div className="externalLinkButton"
                     style={{
                         padding: "10px 10px",
                         backgroundColor: 'var(--buttonColour)'
                     }} onClick={() => {
                    window.open('https://drive.google.com/file/d/1_Kzt6ktK5YIsvnQreWRJ4BFcYWhRVa9q/view', '_blank');
                }}>
                    <div style={{fontSize: "1em"}}>
                        Game Design Document
                    </div>
                </div>
            </div>

            <h1 className="text">
                The game’s narrative is broken down into five chapters in the game with each chapter being broken down
                into its own missions and objectives that not only make sense of the world in which the game takes
                place, but also make sense of the game’s genre and combination of playstyles.
                Follow the links for the breakdown of each chapter and development progress
            </h1>

            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                <div className="internalLinkButton" style={{
                    padding: "15px 20px",
                    marginRight: "10%",
                    backgroundColor: Chapter2 === null ? 'var(--buttonColour)' : !Chapter2 ? 'rgba(252,190,17,0.75)' : 'var(--buttonColour)'
                }} onClick={() => {
                    setChapter2(false);
                }}>
                    <div>
                        Chapter 1
                    </div>
                </div>

                <div className="internalLinkButton"
                     style={{
                         padding: "15px 20px",
                         backgroundColor: Chapter2 ? 'rgba(252,190,17,0.75)' : 'var(--buttonColour)'
                     }} onClick={() => {
                    setChapter2(true);
                }}>
                    <div>
                        Chapter 2
                    </div>
                </div>
            </div>

            {/* CHAPTER 1 */}
            {
                Chapter2 !== null && !Chapter2 &&
                (
                    <div>
                        <h1 className="subHeading">Content</h1>

                        <h1 className="subHeadingExpandable"
                            onClick={() => setChapter1Headings(prevState =>
                                ({...prevState, Introduction: !prevState.Introduction}))}
                            style={{
                                textDecoration: chapter1Headings.Introduction ? "none" : "underline",
                                width: "100%"
                            }}>
                            Introduction
                        </h1>

                        <div ref={(el) => {
                            if (!introElements.current.includes(el)) {
                                introElements.current.push(el);
                            }
                        }}
                             className={`dropdown-content ${chapter1Headings.Introduction ? "show" : ""}`}>
                            <h1 className="text">
                                The first
                                chapter of the story is set in the present. It begins when the
                                protagonist thinks he's going for a nice little vacation at his pal's place without
                                knowing
                                that he's in for a One-Way Ride.
                                The first chapter of the game serves as a crucial introduction to both the game world
                                and
                                its characters. Hence, it should be partly controlled and enacted to establish the
                                relation
                                between the three characters(Mariano, Luca and Paulie) and at the same time, familiarize
                                the
                                player with some of the game's mechanics and systems. This and the final chapter will be
                                in
                                the present and the other chapters are about the past and the relation between these
                                three
                                characters.
                            </h1>
                        </div>

                        <hr className="sectionEndLine"/>

                        <h1 className="subHeadingExpandable"
                            onClick={() => setChapter1Headings(prevState =>
                                ({...prevState, PlayerMovement: !prevState.PlayerMovement}))}
                            style={{textDecoration: chapter1Headings.PlayerMovement ? "none" : "underline"}}>
                            Player Movement
                        </h1>

                        <div ref={(el) => {
                            if (!introElements.current.includes(el)) {
                                introElements.current.push(el);
                            }
                        }}
                             className={`dropdown-content ${chapter1Headings.PlayerMovement ? "show" : ""}`}>

                            <h1 className="text">
                                The chapter is set in a vacation home and begins with a controlled and
                                enacted sequence followed by dialogues, which then erupts into a fight as Mariano
                                tries
                                to
                                save himself. I thought that this would be a good place to actually get the player
                                introduced to some of the gameplay by giving the player a choice to make which would
                                impact
                                the game at a later stage. Since we aren't deep into the story yet, it was an easy
                                decision
                                for me to make. With the rest of the story set in the past, this wouldn't affect the
                                gameplay of the following chapters but would definitely introduce some player
                                interaction in
                                the middle of a cutscene type game-play making it interesting and fun. Once the
                                interactions
                                are over and decisions are taken, the player has control over his actions and is
                                introduced
                                to the main navigation mecahnics. After researching about multiple top-down
                                games(specifically Disco Elysium: a lot of inspiration was takenfor this game), it
                                was
                                clear
                                that click to move was a better alternative for movement as compared to W, A, S and
                                D or
                                any
                                other. Mariano walks to wherever the player clicks.
                            </h1>

                            <ProjectYTVideoTemplate
                                video="https://www.youtube.com/embed/vqST71CwuOY?vq=hd1080&playlist=vqST71CwuOY&loop=1&rel=0"
                                videoDescription={
                                    <div className="videoDescriptionText"><a
                                        className="externalTextLink"
                                        style={{cursor: "none", color: "var(--colour-links)"}}
                                        href="https://shubhgawhade.itch.io/robolution" target="_blank"
                                        rel="noreferrer">
                                        Robolution Game Prototype</a> was a solo project which I made for the AGDS
                                        Game
                                        Jam
                                        where I used Top-Down camera angles with click to move mechanics to try it
                                        out
                                        before implementing it in the Hons Project.</div>}/>

                            <br/>

                            <ProjectYTVideoTemplate
                                video="https://www.youtube.com/embed/H-aQstPWRiw?vq=hd1080&playlist=H-aQstPWRiw&loop=1&rel=0"
                                videoDescription={
                                    <div className="videoDescriptionText">Testing out movement in the game</div>
                                }/>
                            <br/>
                        </div>

                        <hr className="sectionEndLine"/>

                        <h1 className="subHeadingExpandable"
                            onClick={() => setChapter1Headings(prevState =>
                                ({...prevState, Interactables: !prevState.Interactables}))}
                            style={{textDecoration: chapter1Headings.Interactables ? "none" : "underline"}}>
                            Interactables
                        </h1>

                        <div ref={(el) => {
                            if (!introElements.current.includes(el)) {
                                introElements.current.push(el);
                            }
                        }}
                             className={`dropdown-content ${chapter1Headings.Interactables ? "show" : ""}`}>

                            <h1 className="text">
                                I also wanted to implement an interaction system as I was thinking of ways to let the
                                player know more about the world he was in. It would aid us in the future too for when
                                there will be a puzzle type of gameplay added. Looking at how games usually implemented
                                interactable objects, it usually was too simple and felt like a hand-held experience
                                where it was obvious as to what objects are interactable and what arent. It takes away
                                the fun of actually finding things by giving you the answers straight away. Instead,
                                by making it hidden we can peak the curiousity of the player and it would consent for
                                more exploration in each setting. But also, we can't keep interactable objects too hard
                                to find. So the main question was: how to make it obvious for the player but not too
                                obvious as to what can be interacted with? So I came up with a system where the player
                                should be within a certain radius of the object and be in the line of sight for it to
                                actually have an outline showing that it was interactable. The player can only interact
                                with an object when it has an outline.
                            </h1>

                            <ProjectImageTemplate
                                image={chapter1MappedImages['TestingOutline']}
                                imageDescription="Testing the outline"/>

                            <ProjectYTVideoTemplate
                                video="https://www.youtube.com/embed/d1u4VTueNpY?vq=hd1080&playlist=d1u4VTueNpY&loop=1&rel=0"
                                videoDescription={
                                    <div className="videoDescriptionText">
                                        Ragdoll
                                    </div>}
                            />
                            <br/>
                        </div>

                        <hr className="sectionEndLine"/>

                        <h1 className="subHeadingExpandable"
                            onClick={() => setChapter1Headings(prevState =>
                                ({...prevState, PlayerNPCDialogues: !prevState.PlayerNPCDialogues}))}
                            style={{
                                textDecoration: chapter1Headings.PlayerNPCDialogues ? "none" : "underline",
                                width: "100%"
                            }}>
                            Player and NPC Dialogues
                        </h1>

                        <div ref={(el) => {
                            if (!introElements.current.includes(el)) {
                                introElements.current.push(el);
                            }
                        }}
                             className={`dropdown-content ${chapter1Headings.PlayerNPCDialogues ? "show" : ""}`}>
                            <h1 className="text">
                                Now that the interaction is taken care of, What happens after you interact with an
                                object and how is that information conveyed? Now that totally depends on the object in
                                question. In most cases it would be information about the world to make the player aware
                                of his surroundings. There are also cases where it could be used to initiate dialogues
                                with other characters. This partly answers the question about how the information is
                                conveyed and brings us to the next implementation. Dialogues.

                                The starting of the chapter did have dialogues, so I would need to have a dialogue
                                system implemented to show the dialogues along with audio. But that is a problem for
                                later. It would make sense to use the same system here while interacting with other
                                NPC's. While interacting with objects on the other hand, there are two types for now
                                which are information based and just to inspect. Information based can use the same
                                dialogue system and inspect based would have 2D artworks or being able to inspect the
                                model itself. Now that we know what could be the expected functionality and reusability
                                of the interaction system, it would be easier to start off with a system to take in
                                dialogues from the script, sort it by speaker and display it on the UI.
                            </h1>

                            <ShowcaseImagesTemplate
                                showcaseImages={Object.values(playerNPCDialoguesMappedImages.Images)}
                                showcaseDescriptions={Object.values(playerNPCDialoguesMappedImages.Descriptions)}
                                onImageChange={handleImageChange}
                            />

                            {/*<ProjectImageTemplate image={owrMappedImages['DialogueSystem1']}*/}
                            {/*                      imageDescription="(Note: none of the code is final)"*/}
                            {/*/>*/}
                            {/*<ProjectImageTemplate image={owrMappedImages['DialogueSystem2']}*/}
                            {/*                      imageDescription="Creating a dialogue system by reading player dialogues from a .txt file which splits the text which is in a new line and finished player dialogue which ends in a semi-colon(;) and testing it using temp files."*/}
                            {/*/>*/}

                        </div>

                        <hr className="sectionEndLine"/>

                        <h1 className="subHeadingExpandable"
                            onClick={() => setChapter1Headings(prevState =>
                                ({...prevState, EventFlow: !prevState.EventFlow}))}
                            style={{
                                textDecoration: chapter1Headings.EventFlow ? "none" : "underline",
                                width: "100%"
                            }}>
                            Progression of Events
                        </h1>

                        <div ref={(el) => {
                            if (!introElements.current.includes(el)) {
                                introElements.current.push(el);
                            }
                        }}
                             className={`dropdown-content ${chapter1Headings.EventFlow ? "show" : ""}`}>
                            <h1 className="text">
                                Now that the dialogue system is working for the time being with just the basic
                                functionality, I discussed the flow of the story for the first chapter and made a
                                flow-chart with notable events to convert them into game-play using temporary assets to
                                block out the layout.
                            </h1>

                            <ProjectImageTemplate
                                image={chapter1MappedImages['FlowOfEvents']}
                                imageDescription={"Chapter 1 has a cutscene introduction following which the players are introduced to the open world and are taught the basic movemnt and interaction mechanics."}/>

                            <ProjectYTVideoTemplate
                                video="https://docs.google.com/presentation/d/17MfrBBHQOIL37g_oocB7MWAuU4aHCSGOK-DKaNZ-RjE/embed?start=true&loop=true&delayms=6000&rm=minimal"
                                videoDescription={
                                    <div className="videoDescriptionText">
                                        Project overview to attract potential team members
                                    </div>}
                            />

                            <br/>
                        </div>

                        <hr className="sectionEndLine"/>

                        <h1 className="subHeadingExpandable"
                            onClick={() => setChapter1Headings(prevState =>
                                ({...prevState, AssetPipeline: !prevState.AssetPipeline}))}
                            style={{
                                textDecoration: chapter1Headings.AssetPipeline ? "none" : "underline",
                                width: "100%"
                            }}>
                            Assets Pipeline
                        </h1>

                        <div ref={(el) => {
                            if (!introElements.current.includes(el)) {
                                introElements.current.push(el);
                            }
                        }}
                             className={`dropdown-content ${chapter1Headings.AssetPipeline ? "show" : ""}`}>
                            <h1 className="text">
                                We attempted to recruit more 3D artists for our team, but ultimately had to adapt.
                                Leveraging my expertise in 3D art pipelines, I took on the task of creating the assets.
                                Our pipeline involved Blender for modeling, Substance Painter for texturing, and Unity
                                for integration. We evaluated both the Universal Render Pipeline (URP) and the
                                High-Definition Render Pipeline (HDRP), opting for HDRP due to its superior capabilities
                                for PC gaming. This choice allowed us to utilize advanced features like volumetric and
                                local fog effects, as well as the shader graph, to enhance our game's visual quality.
                            </h1>

                            <ProjectYTVideoTemplate
                                video="https://www.youtube.com/embed/GXam6p11NG0?vq=hd1080&playlist=GXam6p11NG0&loop=1&rel=0"
                                videoDescription={
                                    <div className="videoDescriptionText">
                                        First working version of Chapter 1 without new assets
                                    </div>}
                            />

                            <ShowcaseImagesTemplate
                                heading="Pipeline Showcase"
                                showcaseImages={Object.values(pipelineShowcaseMappedImages.Images)}
                                showcaseDescriptions={Object.values(pipelineShowcaseMappedImages.Descriptions)}
                                onImageChange={handleImageChange}
                            />

                            <ShowcaseImagesTemplate
                                heading="Fog Effects in HDRP and URP"
                                showcaseImages={Object.values(HDRPURPMappedImages.Images)}
                                showcaseDescriptions={Object.values(HDRPURPMappedImages.Descriptions)}
                                onImageChange={handleImageChange}
                            />

                        </div>

                        <hr className="sectionEndLine"/>

                        <h1 className="subHeadingExpandable"
                            onClick={() => setChapter1Headings(prevState =>
                                ({...prevState, DialogueAudio: !prevState.DialogueAudio}))}
                            style={{
                                textDecoration: chapter1Headings.DialogueAudio ? "none" : "underline",
                                width: "100%"
                            }}>
                            Automating Dialogue and Audio Integration
                        </h1>

                        <div ref={(el) => {
                            if (!introElements.current.includes(el)) {
                                introElements.current.push(el);
                            }
                        }}
                             className={`dropdown-content ${chapter1Headings.DialogueAudio ? "show" : ""}`}>
                            <h1 className="text">
                                Now we get back to the problem of adding audio to the dialogues. I started by taking the
                                dialogues and generating text-to-speech audio files and importing them in unity. Created
                                a separate class to link the audio to dialogues. I wanted to make this process competely
                                automated so that any of us shouldn't have to manually go and add in audio for parsed
                                dialogues. So I developed a tool for the project which searches through the audio files
                                and returns the audio file for the dialogue. We came up with a naming convention which
                                is:
                                <br/><br/>Script Naming Convention: <b>Chapter#_ScriptID.txt</b><br/>
                                Audio Files Naming Convention: <b>Chapter#_ScriptID_Line#.mp3</b><br/><br/>

                                which we used to automate the process by changing the existing TextReader.cs script to
                                take the first part of the dialogue to give us the speaker and assign respective
                                dialogues from resources on load.
                            </h1>

                            <ShowcaseImagesTemplate
                                showcaseImages={Object.values(dialogueAudioMappedImages.Images)}
                                showcaseDescriptions={Object.values(dialogueAudioMappedImages.Descriptions)}
                                onImageChange={handleImageChange}
                            />

                            {/*<ProjectImageTemplate*/}
                            {/*    image={owrMappedImages['DialogueAudio1']}*/}
                            {/*    imageDescription={"Updated TextReader.cs script which loads the lines of diaogues in the script and assigns each of them the respective audio files."}/>*/}

                            {/*<ProjectImageTemplate*/}
                            {/*    image={owrMappedImages['DialogueAudio2']}*/}
                            {/*    imageDescription={"Class DialogueAudioMatch which stores each dialogue with its audio file and speaker"}/>*/}
                        </div>

                        <hr className="sectionEndLine"/>

                        <br/>

                        <ProjectYTVideoTemplate
                            video="https://docs.google.com/presentation/d/16y6GagihjD3i0t8uCuHDxlOUCAR5NX5H/embed?start=true&loop=true&delayms=6000&rm=minimal"
                            videoDescription={
                                <div className="videoDescriptionText">
                                    Concept Development and Pre-Production Portfolio
                                </div>}
                        />

                    </div>
                )
            }

            {/* TODO: CHAPTER 2 */}
            {
                Chapter2 !== null && Chapter2 &&
                (
                    <div>
                        <h1 className="subHeading">Content</h1>

                        <h1 className="subHeadingExpandable"
                            onClick={() => setChapter2Headings(prevState =>
                                ({...prevState, Introduction: !prevState.Introduction}))}
                            style={{
                                textDecoration: chapter2Headings.Introduction ? "none" : "underline",
                                width: "100%"
                            }}>
                            Introduction
                        </h1>

                        <div ref={(el) => {
                            if (!introElements.current.includes(el)) {
                                introElements.current.push(el);
                            }
                        }}
                             className={`dropdown-content ${chapter2Headings.Introduction ? "show" : ""}`}>
                            <h1 className="text">
                                The second chapter is set in the past and dives deeper into the relation between Luca,
                                Paulie and out protagonist Mariano. It narrates an event about how they were attacked by
                                a rival gang while collecting their wages and the tensions that arise within the gang
                                members leading up to a fight and a confrontation.
                                <br/><br/>
                                The second chapter is where the majority of the gameplay is introduced to the player(at
                                least for the Hons. project prototype). After a brief cutscene, the player is allowed to
                                explore the gas station and the surrounding areas. Here, the hidden intelligence system
                                is used to have a few different choices of dialogue or events which do not change the
                                flow of the story. Following which, the player wil be introduced to the shooting
                                mechanics which will lead to a full blown shoot-out which will take the characters to a
                                nearby warehouse. Here the stealth system will be introduced with a cover system and the
                                chapter ends with a boss fight.
                                <br/><br/>
                                The player would be reminded by his companions who will head to the entrance and remind
                                Mariano that they have to go collect the money from the cashier inside if they spend too
                                much time wandering. There will be several interactables both inside and outside the gas
                                station.
                            </h1>
                        </div>

                        <hr className="sectionEndLine"/>

                        <h1 className="subHeadingExpandable"
                            onClick={() => setChapter2Headings(prevState =>
                                ({...prevState, ExporationLevels: !prevState.ExporationLevels}))}
                            style={{
                                textDecoration: chapter2Headings.ExporationLevels ? "none" : "underline",
                                width: "100%"
                            }}>
                            Levels of Exploration
                        </h1>

                        <div ref={(el) => {
                            if (!introElements.current.includes(el)) {
                                introElements.current.push(el);
                            }
                        }}
                             className={`dropdown-content ${chapter2Headings.ExporationLevels ? "show" : ""}`}>
                            <h1 className="text">
                                Exploration is a key mechanic for the players to gain knowledge in the game. Since
                                chapter 2 begins with exploration, it is essential to have multiple floors in buildings
                                such as warehouses and construction sites. To achieve this, I considered Disco Elysium's
                                implementation of a small loading scene between different levels and locations, which
                                felt continuous despite being separate levels. However, I aimed for a smoother
                                transition without loading screens between parts of a level. Firstly, after talking to
                                my narrative designer and his vision about how he pictured the story to flow between
                                differnt environments. After consulting with my narrative designer on how the story
                                should flow between different environments, we envisioned loading environments and
                                having a transition to switch between locations for a smoother experience. This
                                transition could be an animation.
                                <br/><br/>
                                However, I was not entirely convinced that this solution would look seamless. Therefore,
                                I came up with a system of disappearing floors. The player would enter an indoors
                                trigger which would tell which floor they are on and use a fade script to fade in and
                                out the materials. In this system, the player would enter an indoor trigger that would
                                indicate which floor they are on and use a fade script to fade in and out the materials.
                                Each floor when triggered would activate all triggers above it to detect objects with
                                the fade script on them and make them disappear from view.
                                <br/><br/>
                                The main concern with this approach was whether the game engine could support a dense
                                level in one scene and run on an acceptable frame rate. To solve this issue, I learnt
                                how to use occlusion culling from this detailed guide on using the in-built occusion
                                system and the results were better and can be optimized better when we add the final
                                assets.
                            </h1>

                            {/*<div style={{display: "flex", justifyContent: "center"}}>*/}
                            {/*    <ProjectImageTemplate image={Object.values(chapter2MappedImages)[0]}*/}
                            {/*                          imageDescription="Code to detect when the player enters a level trigger and hides everything above it by detecting collisions between triggers and other objects"/>*/}
                            {/*    <div style={{paddingLeft: "25px"}}/>*/}
                            {/*    <ProjectImageTemplate image={Object.values(chapter2MappedImages)[1]}*/}
                            {/*                          imageDescription="Code to fade or show the materials with a speed"/>*/}
                            {/*</div>*/}

                            <ProjectYTVideoTemplate
                                video="https://www.youtube.com/embed/rTci-fGNJyA?vq=hd1080&playlist=rTci-fGNJyA&loop=1&rel=0"
                                videoDescription={
                                    <div className="videoDescriptionText">
                                        The multiple level fading system with each floor having its own trigger
                                    </div>}
                            />
                            <br/>
                            <ProjectYTVideoTemplate
                                video="https://www.youtube.com/embed/fh45Y2dNnCA?vq=hd1080&playlist=fh45Y2dNnCA&loop=1&rel=0"
                                videoDescription={
                                    <div className="videoDescriptionText">
                                        Stats without Occlusion culling
                                    </div>}
                            />
                            <br/>
                            <ProjectYTVideoTemplate
                                video="https://www.youtube.com/embed/NZ83Q1NMlZ8?vq=hd1080&playlist=NZ83Q1NMlZ8&loop=1&rel=0"
                                videoDescription={
                                    <div className="videoDescriptionText">
                                        Stats with Occlusion culling
                                    </div>}
                            />
                            <br/>
                            <ShowcaseImagesTemplate showcaseImages={Object.values(explorationLevelsMappedImages.Images)}
                                                    showcaseDescriptions={Object.values(explorationLevelsMappedImages.Descriptions)}
                                                    onImageChange={handleImageChange}
                            />
                        </div>

                        <hr className="sectionEndLine"/>

                        <h1 className="subHeadingExpandable"
                            onClick={() => setChapter2Headings(prevState =>
                                ({...prevState, Gunplay: !prevState.Gunplay}))}
                            style={{
                                textDecoration: chapter2Headings.Gunplay ? "none" : "underline",
                                width: "100%"
                            }}>
                            Gunplay
                        </h1>

                        <div ref={(el) => {
                            if (!introElements.current.includes(el)) {
                                introElements.current.push(el);
                            }
                        }}
                             className={`dropdown-content ${chapter2Headings.Gunplay ? "show" : ""}`}>
                            <h1 className="text">
                                Now that we can explore our world, the next part of the story involves gunplay. I
                                decided to implement a very basic shooting system which will be iterated on later. Since
                                we use clicks to move around, we can't use click-to-shoot at the same time without
                                making it too complicated for the player. Having to switch between states manually would
                                be undesirable.
                                <br/><br/>
                                Therefore, the idea for gunplay is to have different vantage points from which the
                                player can shoot at enemies. Once they are at a vantage point, they won't be able to
                                move and will be able to use their mouse to aim and shoot at a location. When the player
                                reaches a vantage point, they won't be able to move and will use their mouse to aim and
                                shoot at a location. In top-down games, gunplay usually involves shooting bullets at a
                                certain height, as it's hard to estimate the trajectory from the camera angle. However,
                                I wanted to create a more dynamic system where the player can shoot down at enemies from
                                rooftops or target different parts of their bodies. We will re-visit the issue of
                                estimating the trajectory later on after seeing how the gunplay fits in.
                            </h1>

                            <ProjectYTVideoTemplate
                                video="https://www.youtube.com/embed/HwgGmObz48Q?vq=hd1080&playlist=HwgGmObz48Q&loop=1&rel=0"
                                videoDescription={
                                    <div className="videoDescriptionText">
                                        Player switches to gunplay when they enter a trigger and shoots at the location
                                        with unlimited fire-rate
                                    </div>}
                            />
                            <br/>
                            <ShowcaseImagesTemplate showcaseImages={Object.values(gunplayMappedImages.Images)}
                                                    showcaseDescriptions={Object.values(gunplayMappedImages.Descriptions)}
                                                    onImageChange={handleImageChange}
                            />
                        </div>

                        <hr className="sectionEndLine"/>

                        <h1 className="subHeadingExpandable"
                            onClick={() => setChapter2Headings(prevState =>
                                ({...prevState, CrouchingCoverMechanics: !prevState.CrouchingCoverMechanics}))}
                            style={{
                                textDecoration: chapter2Headings.CrouchingCoverMechanics ? "none" : "underline",
                                width: "100%"
                            }}>
                            Crouching and Cover mechanics
                        </h1>

                        <div ref={(el) => {
                            if (!introElements.current.includes(el)) {
                                introElements.current.push(el);
                            }
                        }}
                             className={`dropdown-content ${chapter2Headings.CrouchingCoverMechanics ? "show" : ""}`}>
                            <h1 className="text">
                                With a basic shooting set up, I realized the need for a better cover system that would
                                allow the player to shoot from behind cover while remaining hidden. This would be
                                directly connected to the stealth mechanics, enabling the player to crouch and move
                                behind objects. Although the crouching animation was present, it lacked a dynamic feel
                                to it where Mariano would adjust his height based on the height of the cover, showing
                                only a part of his head to remain concealed.
                                <br/><br/>
                                To achieve this, I utilized a relatively new Unity package functionality called Unity
                                Animation Rigging which permitted me to add constraints to existing animations.
                                Consequently, when the player crouches and is within the cover trigger, the cover
                                animation rig is activated, dynamically adjusting Mariano's height while still in the
                                crouched state.
                                <br/><br/>
                                Getting a basic setup for this was important as Chapter 2 ends with the game introducing
                                stealth mechanics for the player to use it in the following chapter. I am quite
                                satisfied with the dynamic crouching achieved as it may not be final, but is a buiding
                                block to integrate the stealth aspect into the game.
                            </h1>

                            <ProjectYTVideoTemplate
                                video="https://www.youtube.com/embed/VDUipQgjkHM?vq=hd1080&playlist=VDUipQgjkHM&loop=1&rel=0"
                                videoDescription={
                                    <div className="videoDescriptionText">
                                        Dynamic crouching system between covers
                                    </div>}
                            />
                            <br/>
                            <ProjectImageTemplate
                                image={chapter2MappedImages['CrouchingCoverCode']}
                                imageDescription="Code to dynamically adjusting Mariano's height and colliders size"/>
                        </div>

                        <hr className="sectionEndLine"/>

                        <h1 className="subHeadingExpandable"
                            onClick={() => setChapter2Headings(prevState =>
                                ({...prevState, ShootingCoverMechanics: !prevState.ShootingCoverMechanics}))}
                            style={{
                                textDecoration: chapter2Headings.ShootingCoverMechanics ? "none" : "underline",
                                width: "100%"
                            }}>
                            Shooting and Cover
                        </h1>

                        <div ref={(el) => {
                            if (!introElements.current.includes(el)) {
                                introElements.current.push(el);
                            }
                        }}
                             className={`dropdown-content ${chapter2Headings.ShootingCoverMechanics ? "show" : ""}`}>
                            <h1 className="text">
                                In order to integrate the shooting and cover mechanics individually, I had to make
                                significant changes to the current CharacterController.cs script. I separated the two
                                functions based on the character state and the OnClickedPlayerBehaviour() to extend the
                                existing functionality.
                                <br/><br/>
                                Shooting and cover have been individually implemented. But the main challenge was
                                finding a way to let the player know which objects could be used as vantage points for
                                shooting and which ones could be used as cover. I expanded upon the functionality of the
                                Interactables by introducing three types of interactables: Dialogues, Information-based,
                                and Gunplay Cover, each being color-coded to separate their functionality. For other
                                cover objects, there is no interaction required since the player would be already
                                crouched if they're trying to sneak around. When the cover objects detect that a player
                                is behind them, they dynamically adjust their height to proivde cover.
                                <br/><br/>
                                When the player clicks on a Gunplay Cover interactable, they are taken to that location
                                and crouch behind it, which changes their state to Gunplay mode for shooting. Once the
                                player is behind cover, they can only interact with other vantage points to switch
                                positions or hit the crouch button to uncrouch and return to the Exploration state. If
                                they decide to shoot, the player stands up and shoots in the direction, stays for a
                                while to allow the player to shoot more if needed, and then returns to crouching behind
                                cover.
                            </h1>

                            <ProjectYTVideoTemplate
                                video="https://www.youtube.com/embed/BdsUpBgvTCQ?vq=hd1080&playlist=BdsUpBgvTCQ&loop=1&rel=0"
                                videoDescription={
                                    <div className="videoDescriptionText">
                                        Combining the shooting and cover mechanics
                                    </div>}
                            />
                            <br/>

                            <ShowcaseImagesTemplate showcaseImages={Object.values(shootingMappedImages.Images)}
                                                    showcaseDescriptions={Object.values(shootingMappedImages.Descriptions)}
                                                    onImageChange={handleImageChange}
                            />

                            <ShowcaseImagesTemplate showcaseImages={Object.values(interactablesMappedImages.Images)}
                                                    showcaseDescriptions={Object.values(interactablesMappedImages.Descriptions)}
                                                    onImageChange={handleImageChange}
                            />
                        </div>

                        <hr className="sectionEndLine"/>

                        <h1 className="subHeadingExpandable"
                            onClick={() => setChapter2Headings(prevState =>
                                ({
                                    ...prevState,
                                    IntelligenceDrunkennessSystem: !prevState.IntelligenceDrunkennessSystem
                                }))}
                            style={{
                                textDecoration: chapter2Headings.IntelligenceDrunkennessSystem ? "none" : "underline",
                                width: "100%"
                            }}>
                            Intelligence and Drunkenness System
                        </h1>

                        <div ref={(el) => {
                            if (!introElements.current.includes(el)) {
                                introElements.current.push(el);
                            }
                        }}
                             className={`dropdown-content ${chapter2Headings.IntelligenceDrunkennessSystem ? "show" : ""}`}>
                            <h1 className="text">
                                Intelligence: We also planned to have a hidden intelligence system to have a puzzle like
                                mechanic that give the players extra benefits by making it easier to progress. At first,
                                I tried having branching paths which just gave players different dialogues and it
                                wouldnt really affect the story as this wouldn't fit the theme of a linear game. At the
                                end of brainstorming we agreed upon having different dialogues which wouldn't branch out
                                but instead would allow for the progression of the story by using the unlocking of
                                events and interactions. The player interacts and gains more info. Based on the
                                information collected, each meaningful interactable adds to their intelligence level and
                                will unlock interactables which required a higher level.
                                <br/><br/>
                                Drunkenness: The players start the chapter by drinking a bit and have their drunk meter
                                half filled. The meter decreases over time and the player can choose to get drunk or
                                not. This will directly affect their experience by having innacurate shooting but will
                                take upon lesser damage or have accurate shooting with higher damage.
                            </h1>

                            <ShowcaseImagesTemplate
                                showcaseImages={Object.values(intelligenceSystemMappedImages.Images)}
                                showcaseDescriptions={Object.values(intelligenceSystemMappedImages.Descriptions)}
                                onImageChange={handleImageChange}
                            />
                        </div>

                        <hr className="sectionEndLine"/>

                        <h1 className="subHeadingExpandable"
                            onClick={() => setChapter2Headings(prevState =>
                                ({
                                    ...prevState,
                                    RagdollPhysics: !prevState.RagdollPhysics
                                }))}
                            style={{
                                textDecoration: chapter2Headings.RagdollPhysics ? "none" : "underline",
                                width: "100%"
                            }}>
                            Ragdoll Physics
                        </h1>

                        <div ref={(el) => {
                            if (!introElements.current.includes(el)) {
                                introElements.current.push(el);
                            }
                        }}
                             className={`dropdown-content ${chapter2Headings.RagdollPhysics ? "show" : ""}`}>
                            <h1 className="text">
                                After successfully integrating the shooting and cover system, the next logical step was
                                to introduce a health and death system for all in-game characters. After adding somoe
                                code, I managed to incorporate a mechanism that accurately transferred the force of the
                                bullet to the specific bone it hit, thereby triggering the pre-existing ragdoll feature.
                                The result was an immersive and satisfying ragdoll animation when a character met their
                                untimely demise. The bullets also have a damage variable which I used to build a health
                                system and inflict damage on whichever character the bullet hits.
                            </h1>

                            <ProjectYTVideoTemplate
                                video="https://www.youtube.com/embed/EKhMRI_lvtU?vq=hd1080&playlist=EKhMRI_lvtU&loop=1&rel=0"
                                videoDescription={
                                    <div className="videoDescriptionText">
                                        Combining the shooting and cover mechanics
                                    </div>}
                            />
                            <br/>

                            <ShowcaseImagesTemplate showcaseImages={Object.values(ragdollPhysicsMappedImages.Images)}
                                                    showcaseDescriptions={Object.values(ragdollPhysicsMappedImages.Descriptions)}
                                                    onImageChange={handleImageChange}
                            />
                        </div>

                        <hr className="sectionEndLine"/>
                    </div>
                    // <div>
                    //
                    //     <ProjectImageTemplate
                    //         image="https://img.itch.zone/aW1hZ2UvMjg4Nzk1Ny8xNzI3MTIyOC5wbmc=/original/VQYwun.png"
                    //         imageDescription="Concept art for the game"/>
                    //
                    //     <ProjectYTVideoTemplate
                    //         video="https://www.youtube.com/embed/-M7cxTxlAkw?vq=hd1080&playlist=-M7cxTxlAkw&rel=0"
                    //         videoDescription={
                    //             <div className="videoDescriptionText">
                    //                 Gameplay
                    //             </div>}
                    //     />
                    //
                    // </div>
                )
            }

            <div style={{marginTop: "2%"}}>
                <h1 className="subHeading">Play the Prototype</h1>
                <iframe title="Itch io" frameBorder="0"
                        src="https://itch.io/embed/2887957?linkback=true&dark=true"
                        style={{width: "100%", maxWidth: "860px"}}
                        height="50%"><a href="https://shubhgawhade.itch.io/one-way-ride">One-Way Ride by
                    shubhgawhade</a></iframe>
            </div>

            {/* GITHUB REPO */}
            <div style={{
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "8px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 1)"
            }}>
                <h1 className="subHeading">Github Repository</h1>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <a className="externalLinkButton"
                       style={{
                           display: "flex",
                           flexDirection: "column",
                           alignItems: "center",
                           textDecoration: "none",
                           width: "60%",
                           padding: "2%",
                           marginBottom: "5%",
                           color: "white",
                           cursor: "none"
                       }}
                       href="https://github.com/shubhgawhade/Abertay-Hons-Demo-HDRP"
                       rel="noreferrer"
                       target="_blank">
                        <img style={{width: "10%"}} src={SoftwareTags.Github.Icon}
                             alt={`${SoftwareTags.Github.Name}`}/>
                        Abertay-Hons-Demo-HDRP</a>
                </div>
                {commitData.length > 0 && (
                    <div id="display" style={{maxHeight: "50vh", overflowY: "auto"}}>
                        {commitData.map((commit, index) => (
                            <div key={index} style={{color: "white"}}>
                                {commit.commit.message.split("\n").map((line, lineIndex) => (
                                    <div key={lineIndex}>
                                        {lineIndex === 0 ? (
                                            <a className="externalLinkButton"
                                               style={{
                                                   textDecoration: "none",
                                                   padding: "2%",
                                                   width: "80%",
                                                   color: "white",
                                                   cursor: "none"
                                               }}
                                               href={commit.html_url} target="_blank" rel="noreferrer">
                                                <strong>{line}</strong>
                                            </a>
                                        ) : (
                                            <div style={{
                                                width: "80%",
                                                textAlign: "left",
                                                margin: "0 auto"
                                            }}>{line}</div>
                                        )}
                                        <br/>
                                    </div>
                                ))}
                                <br/>-------
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

const OWRProject =
    {
        backdropColor: 'rgba(0,0,0,0.75)',
        // boxShadowColor: '#809eff',
        boxShadowColor: '#000000',
        name: 'One-Way Ride',
        imageURL: 'https://cdn.pixabay.com/photo/2022/05/17/02/11/fractal-7201481_1280.jpg',
        imageDescription: 'One-Way Ride',
        videoURL: 'https://www.youtube.com/embed/-M7cxTxlAkw?vq=hd1080&playlist=-M7cxTxlAkw',//1dgaWUw448r4zpPnBCNh6qnDsTjAwLlnk
        videoDescription: 'One-Way Ride Gameplay',
        // videoURL: 'https://drive.google.com/file/d/1dgaWUw448r4zpPnBCNh6qnDsTjAwLlnk/preview',
        showcaseImages:
            [
                // 'https://lh3.googleusercontent.com/KHfoS1VEN-KKGhDi4Je3fHQ91WDOWT4jkqnEBV24phaU88rScR8k_wsOneTc0_eUZ9JxxYrAbRaOAh2D5WiPIY9MOojM15gDRhf6ZsZ8UArF53Sc61qxNQ7Y6MD7ammOuA=w1280',
                'https://img.itch.zone/aW1hZ2UvMjg4Nzk1Ny8xNzI3MTIyOC5wbmc=/794x1000/1IVeq3.png',
                'https://img.itch.zone/aW1hZ2UvMjg4Nzk1Ny8xNzI3MTIyNy5wbmc=/794x1000/71V%2Fwa.png',
                'https://img.itch.zone/aW1hZ2UvMjg4Nzk1Ny8xNzI3MTIyOS5wbmc=/794x1000/qmzRMa.png',
                'https://img.itch.zone/aW1hZ2UvMjg4Nzk1Ny8xNzI3MTIyNi5wbmc=/794x1000/R%2BZM3H.png',
                'https://img.itch.zone/aW1hZ2UvMjg4Nzk1Ny8xNzI3MTIzMC5wbmc=/794x1000/A9Ee3v.png',
            ],
        altText:
            [
                // 'Image1',
                // 'Image2',
            ],
        softwareTags:
            [
                // SoftwareTags.React,
                // SoftwareTags.Docker,
                // SoftwareTags.Node,
                // SoftwareTags.SQL,
                // SoftwareTags.AWS,
                // SoftwareTags.CPlusPlus,
                SoftwareTags.Unity,
                SoftwareTags.CSharp,
                // SoftwareTags.Redis,
                // SoftwareTags.SocketIO,
                // SoftwareTags.DirectX,
                SoftwareTags.Blender,
                SoftwareTags.ThreeDSMax,
                // SoftwareTags.Python,
                // SoftwareTags.Java,
                SoftwareTags.SubstancePainter,
                SoftwareTags.Photoshop,
                // SoftwareTags.Unreal,
                // SoftwareTags.AndroidStudio,
                SoftwareTags.Github,

            ],
        clientTags:
            [
                // SoftwareTags.MythosLabs,
                // SoftwareTags.Youtube,
            ],
        body: Description,
    };

export {OWRProject};