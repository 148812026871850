import React from "react";

const ProjectYTVideoTemplate = ({ video, videoDescription }) =>
{
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ position: 'relative', width: '100%', paddingBottom: '56.24%' }}>
                <iframe className="projectYTVideo"
                        src={video}
                        title="One-Way Ride Gameplay"
                        frameBorder="0"
                        allowFullScreen
                        referrerPolicy="strict-origin-when-cross-origin">
                </iframe>
            </div>
            {videoDescription}
        </div>
    );
}

export default ProjectYTVideoTemplate;

{/*VIDEO TEMPLATE*/}

{/*<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>*/}
{/*    <div style={{position: 'relative', width: '100%', paddingBottom: '56.24%'}}>*/}

{/*        <iframe className="projectYTVideo"*/}
{/*                src={"https://www.youtube.com/embed/vqST71CwuOY?vq=hd1080&loop=1&rel=0&playlist=vqST71CwuOY"}*/}
{/*                title="One-Way Ride Gameplay"*/}
{/*                frameBorder="0"*/}
{/*                allowFullScreen*/}
{/*                referrerPolicy="strict-origin-when-cross-origin"*/}
{/*        ></iframe>*/}

{/*    </div>*/}
{/*</div>*/}
{/*<div style={{*/}
{/*    fontFamily: "'Roboto Mono', monospace",*/}
{/*    color: '#fcbe11',*/}
{/*    textAlign: 'center',*/}
{/*    marginTop: '10px',*/}
{/*    fontSize: '1.5rem',*/}
{/*    fontWeight: 700*/}
{/*}}>*/}
{/*    VIDEO DESCRIPTION*/}
{/*</div>*/}