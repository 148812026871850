import React from "react";

const ProjectImageTemplate = ({ image, imageDescription, onClick = null }) =>
{
    return (
        <div>
            <img style={{maxHeight: '60vh', maxWidth: '100%', borderRadius: "6px", boxShadow: '0 2px 5px rgba(0, 0, 0, 1)'}}
                 src={image}
                 alt={imageDescription}
                 onClick={onClick}
            />
            <div className="imageDescriptionText">
                {imageDescription}
            </div>
        </div>
    );
}

export default ProjectImageTemplate;

{/*IMAGE TEMPLATE*/}

{/*<img style={{*/}
{/*    width: "100%",*/}
{/*    borderRadius: "6px",*/}
{/*    boxShadow: '0 2px 5px rgba(0, 0, 0, 1)'*/}
{/*}}*/}
{/*     src="https://img.itch.zone/aW1hZ2UvMjg4Nzk1Ny8xNzI3MTIyOC5wbmc=/original/VQYwun.png"*/}
{/*     alt="Description A"/>*/}

{/*<div style={{*/}
{/*    fontFamily: "'Roboto Mono', monospace",*/}
{/*    color: '#fcbe11',*/}
{/*    textAlign: 'center',*/}
{/*    marginTop: '2px',*/}
{/*    marginBottom: '10px',*/}
{/*    paddingBottom: '20px',*/}
{/*    fontSize: '1.5rem',*/}
{/*    fontWeight: 700*/}
{/*}}>*/}
{/*    Concept art for the game*/}
{/*</div>*/}