import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import {useEffect, useRef, useState} from "react";
import {
    ACESFilmicToneMapping,
    AmbientLight, AnimationMixer,
    BoxGeometry, Clock, LoopOnce,
    Mesh,
    MeshBasicMaterial,
    PerspectiveCamera,
    PointLight,
    Raycaster,
    Scene,
    SkeletonHelper,
    SRGBColorSpace,
    Texture,
    Vector2,
    WebGLRenderer
} from "three";
import {useNavigate} from "react-router-dom";
import DescriptionCard from "./DescriptionCard";
import {AboutMe} from "./AboutMe";

const renderer = new WebGLRenderer({ alpha: true, antialias: true });
renderer.setPixelRatio(window.devicePixelRatio);
renderer.toneMapping = ACESFilmicToneMapping;
renderer.outputEncoding = SRGBColorSpace;

// class Cube extends Mesh {
//     constructor() {
//         super();
//
//         const geometry = new BoxGeometry();
//         const material = new MeshStandardMaterial();
//         material.color.set("blue");
//
//         this.geometry = geometry;
//         this.material = material;
//     }
//
//     update()
//     {
//         // this.rotation.x += 0.01;
//         // this.rotation.y += 0.01;
//     }
// }

let roomAnims = [];
let meAnims = [];
let interactableObjects = [];
let wallNum = 0;

const Home = () =>
{
    // let stats;
    // let skeleton;
    let clock = new Clock();

    const scene = new Scene();
    const camera = new PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );

    const geometry = new BoxGeometry( 1, 1, 1 );
    const material = new MeshBasicMaterial( { color: 0x00ff00 } );
    const cube = new Mesh( geometry, material );

    // const cube1 = new Cube();
    // scene.add(cube1);
    // scene.add( cube );
    const light = new AmbientLight( '#ffffff' ); // soft white light
    scene.add( light );

    // POINT LIGHT
    const pointLight = new PointLight(0xffffff, 5);
    pointLight.position.set(0, 1.5, 2);
    scene.add(pointLight);

    // const pointLight1 = new PointLight(0xffffff, 5);
    // pointLight1.position.set(0, 1.5, -3);
    // scene.add(pointLight1);

    const [currentIntersection, setCurrentIntersection] = useState(null);
    const [showAbout, setShowAbout] = useState(false);
    const [clicked, setClicked] = useState(false);

    const loader = new GLTFLoader();

    const me = useRef(null);
    const room = useRef(null);
    const meMixer = useRef(null);
    const roomMixer = useRef(null);
    const raycaster = new Raycaster();
    const mouse = new Vector2(-1, 1);

    const ref = useRef(null);
    const navigate = useNavigate();

    useEffect(() =>
    {
        scene.background = new Texture('var(--bgImage)' );
        renderer.setSize( window.innerWidth, window.innerHeight );
        renderer.setAnimationLoop( animate );
        ref.current.appendChild( renderer.domElement );

        function onMouseMove(event)
        {
            mouse.set((event.clientX / window.innerWidth) * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);
            // console.log(mouse);
        }

        function onTouchEnd()
        {
            setTimeout(() =>
            {
                mouse.set(-1, 1);
            }, 50);
        }

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('touchend', onTouchEnd);

        loader.load( `${process.env.PUBLIC_URL}/Models/ShubhAvatar2.glb`, function ( glb )
        {
            if(!me.current)
            {
                me.current = glb.scene
                scene.add(me.current);

                interactableObjects.push(me.current);

                me.current.traverse( function ( object )
                {

                    if ( object.isMesh ) object.castShadow = true;

                } );

                // skeleton = new SkeletonHelper( me );
                // skeleton.visible = true;
                // scene.add(skeleton);

                meAnims = glb.animations;
                meMixer.current = new AnimationMixer(me.current);

                // Walking
                const action0 = meMixer.current.clipAction(meAnims[4]);
                action0.reset().play();

                // mixer.current = new AnimationMixer(me.current);
                //
                // const clip = animations[1];
                // const action = mixer.current.clipAction(clip);
                // action.play();

                console.log(meAnims);
            }
        }, undefined, function ( error )
        {

            console.error( error );
        });

        loader.load( `${process.env.PUBLIC_URL}/Models/room2.glb`, function ( glb )
        {
            if(!room.current)
            {
                room.current = glb.scene
                scene.add(room.current);

                // tierWideShelf_01005
                interactableObjects.push(room.current.children[0].children[10]);

                // Github
                interactableObjects.push(room.current.children[0].children[1]);

                // Itchio
                interactableObjects.push(room.current.children[0].children[2]);

                // LinkedIn
                interactableObjects.push(room.current.children[0].children[3]);

                // oofda
                interactableObjects.push(room.current.children[0].children[4]);

                // 35
                // console.log(room.current.children[0].children);
                room.current.traverse( function ( object )
                {

                    // console.log(object);

                    if ( object.isMesh ) object.castShadow = true;

                } );

                // skeleton = new SkeletonHelper( me );
                // skeleton.visible = true;
                // scene.add(skeleton);

                roomAnims = glb.animations;
                roomMixer.current = new AnimationMixer(room.current);

                // const clip = animations[0];
                // const action = mixer1.current.clipAction(clip);
                // action.play();

                console.log(roomAnims);
            }
        }, undefined, function ( error )
        {

            console.error( error );
        });

        // camera.lookAt(camera.getWorldDirection());

        // const controls = new OrbitControls( camera, renderer.domElement );
        // controls.update();

        camera.position.set(0, 1.5, 1.5);


        function animate()
        {
            cube.rotation.x += 0.01;
            cube.rotation.y += 0.01;

            // cube1.update();

            // RAYCAST TARGETS
            // Improved existing raycast system to hold only 1 object so that when it is clicked on, an action is performed
            if(me.current && room.current)
            {
                // me.rotation.y += 0.01;
                raycaster.setFromCamera(mouse, camera);
                const intersection = raycaster.intersectObjects(interactableObjects, true)[0];

                // console.log(intersection);

                if(intersection === undefined && (currentIntersection !== "" || currentIntersection !== null))
                {
                    setCurrentIntersection("");
                }
                else if(currentIntersection !== "me" && intersection?.object.parent.name === "me")
                {
                    setCurrentIntersection("me");
                }
                else if(currentIntersection !== "tierWideShelf_01005"
                    && (intersection?.object.name === "tierWideShelf_01005" || intersection?.object.parent.name === "tierWideShelf_01005"))
                {
                    setCurrentIntersection("portfolio");
                }
                else if(currentIntersection !== "Itchio" && intersection?.object.name === "Itchio")
                {
                    setCurrentIntersection("Itchio");
                }
                else if(currentIntersection !== "Github" && intersection?.object.name === "Github")
                {
                    setCurrentIntersection("Github");
                }
                else if(currentIntersection !== "LinkedIn" && intersection?.object.name === "LinkedIn")
                {
                    setCurrentIntersection("LinkedIn");
                }
                else if(currentIntersection !== "oofda" && intersection?.object.parent.name === "oofda")
                {
                    setCurrentIntersection("oofda");
                }
            }

            // Get the time elapsed since the last frame, used for mixer update
            const mixerUpdateDelta = clock.getDelta();

            // Update the animation mixer, the stats panel, and render this frame
            meMixer.current?.update( mixerUpdateDelta );
            roomMixer.current?.update( mixerUpdateDelta );

            renderer.render( scene, camera );
        }

        function onWindowResize()
        {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        }

        window.addEventListener('resize', onWindowResize, { passive: true });

        return () =>
        {
            if(ref.current)
            {
                renderer.dispose();
                ref.current.removeChild(renderer.domElement);
            }
            window.removeEventListener('resize', onWindowResize);
            window.removeEventListener('mousemove', onMouseMove);
        };
    }, []);

    let timeoutId;

    function playAnims()
    {
        if (roomMixer.current)
        {
            if (wallNum === 0)
            {
                clearTimeout(timeoutId);
                meMixer.current.stopAllAction();

                // SitToStand
                let action3 = meMixer.current.clipAction(meAnims[1]);
                action3.timeScale = 2;
                action3.reset().play();
                action3.setLoop(LoopOnce);
                action3.clampWhenFinished = true;

                timeoutId = setTimeout(() =>
                {
                    meMixer.current.stopAllAction();

                    // Walking
                    action3 = meMixer.current.clipAction(meAnims[4]);
                    action3.reset().play();
                    action3.clampWhenFinished = true;
                }, meAnims[1].duration / 2 * 1000);

                // mixer.current.stopAllAction();
                // const action0 = mixer.current.clipAction(meAnims[4]);
                // action0.reset().play();

                roomMixer.current.stopAllAction();

                // RoomWallsAction
                const action1 = roomMixer.current.clipAction(roomAnims[2]);
                action1.reset().play();
                action1.setLoop(LoopOnce);
                action1.clampWhenFinished = true;

                // ChairActionReverse
                const action2 = roomMixer.current.clipAction(roomAnims[4]);
                action2.reset().play();
                action2.setLoop(LoopOnce);
                action2.clampWhenFinished = true;

                // MonitorBack
                const action4 = roomMixer.current.clipAction(roomAnims[5]);
                action4.timeScale = 2;
                action4.reset().play();
                action4.setLoop(LoopOnce);
                action4.clampWhenFinished = true;
            }
            else
            {
                clearTimeout(timeoutId);

                meMixer.current.stopAllAction();

                // StandToSit
                let action0 = meMixer.current.clipAction(meAnims[2]);
                action0.reset().play();
                action0.setLoop(LoopOnce);
                action0.clampWhenFinished = true;

                roomMixer.current.stopAllAction();

                // RoomRotate2
                const action1 = roomMixer.current.clipAction(roomAnims[1]);
                action1.reset().play();
                action1.setLoop(LoopOnce);
                action1.clampWhenFinished = true;

                // ChairAction
                const action2 = roomMixer.current.clipAction(roomAnims[3]);
                action2.reset().play();
                action2.setLoop(LoopOnce);
                action2.clampWhenFinished = true;

                // MonitorFront
                const action3 = roomMixer.current.clipAction(roomAnims[6]);
                action3.timeScale = 2;
                action3.reset().play();
                action3.setLoop(LoopOnce);
                action3.clampWhenFinished = true;
            }
        }
    }

    useEffect(() =>
    {
        let lastTouchY = null;
        let touchMoveTimeout = null;

        const handleTouchMove = (event) =>
        {
            if(showAbout) return;

            const touch = event.touches[0];
            if (lastTouchY === null)
            {
                lastTouchY = touch.clientY;
                return;
            }
            const delta = touch.clientY - lastTouchY;
            lastTouchY = touch.clientY;

            // console.log(delta);
            if(Math.abs(delta) < 3)
            {
                lastTouchY = null;
                return;
            }

            clearTimeout(touchMoveTimeout);
            touchMoveTimeout = setTimeout(() =>
            {
                if(wallNum === 0 && delta > 0) return;
                if(wallNum === 1 && delta < 0) return;

                wallNum += delta < 0 ? 1 : -1;
                wallNum = Math.abs(wallNum);
                wallNum %= 2;

                console.log(wallNum);
                playAnims();

                lastTouchY = null;
            }, 100);
        }

        function onScroll(event)
        {
            if(showAbout) return;

            const delta = event.deltaY;

            if(wallNum === 0 && delta < 0) return;
            if(wallNum === 1 && delta > 0) return;

            wallNum += delta > 0 ? 1 : -1;
            wallNum = Math.abs(wallNum);
            wallNum %= 2;
            // me.current.rotation.y += (delta > 0 ? Math.PI / 2 : -Math.PI / 2);

            // console.log(wallNum);
            playAnims();
        }

        window.addEventListener('wheel', onScroll, { passive: true });
        window.addEventListener('touchmove', handleTouchMove, { passive: true });

        return () =>
        {
            window.removeEventListener('wheel', onScroll);
            window.removeEventListener('touchmove', handleTouchMove);
        }
    }, [showAbout]);

    useEffect(() =>
    {
        // RESET HIGHLIGHTS

        interactableObjects.forEach((object) =>
        {
            object.traverse((child) =>
            {
                if (child.isMesh)
                {
                    child.material.emissive.setHex(0x000000); // Original color
                }
            });
        });

        if(currentIntersection && !showAbout)
        {
            switch (currentIntersection)
            {
                case "me":

                    me.current.traverse((child) =>
                    {
                        if (child.isMesh)
                        {
                            child.material.emissive.set("rgb(71,40,0)"); // Highlight color
                        }

                        if(clicked && !showAbout)
                        {
                            setShowAbout(true);
                        }
                    });

                    break;


                case "portfolio":

                    room.current.children[0].children[10].traverse((child) =>
                    {
                        if (child.isMesh)
                        {
                            child.material.emissive.set("rgb(71,40,0)"); // Highlight color
                        }

                        if(clicked && window.location.pathname !== '/portfolio')
                        {
                            wallNum = 0;
                            navigate('/portfolio');
                        }
                    });

                    break;

                case "Github":

                    room.current.children[0].children[1].traverse((child) =>
                    {
                        if (child.isMesh)
                        {
                            child.material.emissive.set("rgb(0,79,255)"); // Highlight color
                        }

                        if(clicked)
                        {
                            setTimeout(() =>
                            {
                                window.open("https://github.com/shubhgawhade", '_blank');

                            }, 100);
                        }
                    });

                    break;

                case "Itchio":

                    room.current.children[0].children[2].traverse((child) =>
                    {
                        if (child.isMesh)
                        {
                            child.material.emissive.set("rgb(0,79,255)"); // Highlight color
                        }

                        if(clicked)
                        {
                            setTimeout(() =>
                            {
                                window.open("https://shubhgawhade.itch.io/", '_blank');

                            }, 100);
                        }
                    });

                    break;

                case "LinkedIn":

                    room.current.children[0].children[3].traverse((child) =>
                    {
                        if (child.isMesh)
                        {
                            child.material.emissive.set("rgb(0,79,255)"); // Highlight color
                        }

                        if(clicked)
                        {
                            setTimeout(() =>
                            {
                                window.open("https://www.linkedin.com/in/shubhgawhade/", '_blank');

                            }, 100);
                        }
                    });

                    break;

                case "oofda":

                    room.current.children[0].children[4].traverse((child) =>
                    {
                        if (child.isMesh)
                        {
                            child.material.emissive.set("rgb(0,79,255)"); // Highlight color
                        }

                        if(clicked)
                        {
                            setTimeout(() =>
                            {
                                window.open("https://sites.google.com/view/oofda", '_blank');

                            }, 100);
                        }
                    });

                    break;
            }
        }

        function handleClick()
        {
            if(showAbout) return;

            if(!currentIntersection)
            {
                setClicked(true);
                setTimeout(() =>
                {
                    setClicked(false);
                }, 100);
            }

            switch (currentIntersection)
            {
                case "me":

                    setShowAbout(true);

                    break;


                case "portfolio":

                    wallNum = 0;
                    navigate('/portfolio');

                    break;

                case "Github":

                    window.open("https://github.com/shubhgawhade", '_blank');

                    break;

                case "Itchio":

                    window.open("https://shubhgawhade.itch.io/", '_blank');

                    break;

                case "LinkedIn":

                    window.open("https://www.linkedin.com/in/shubhgawhade/", '_blank');

                    break;

                case "oofda":

                    window.open("https://sites.google.com/view/oofda", '_blank');

                    break;
            }
        }

        window.addEventListener('click', handleClick, {passive: true});

        return () =>
        {
            window.removeEventListener('click', handleClick);
        };
    }, [currentIntersection, showAbout]);

    return (
        <div>
            { showAbout &&
                <DescriptionCard project={AboutMe} onClose={()=>
                {
                    setShowAbout(false);
                    wallNum++;
                    wallNum %= 2;
                    playAnims();
                }} />
            }
            <div ref={ref} />
        </div>
    );
}

export default Home;