interface Software
{
    Name: string,
    Icon: string,
    Link: string
}

export const SoftwareTags: { [key: string]: Software } =
{
    React:
    {
        Name: "React",
        Icon: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original-wordmark.svg",
        Link: "https://reactjs.org/"
    },
    Node:
    {
        Name: "Node",
        Icon: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nodejs/nodejs-original-wordmark.svg",
        Link: "https://nodejs.org/en"
    },
    CSharp:
    {
        Name: "C#",
        Icon: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/csharp/csharp-original.svg",
        Link: "https://docs.microsoft.com/en-us/dotnet/csharp/"
    },
    CPlusPlus:
    {
        Name: "C++",
        Icon: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/cplusplus/cplusplus-original.svg",
        Link: "https://www.cplusplus.com/"
    },
    Python:
    {
        Name: "Python",
        Icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/python/python-original-wordmark.svg',
        Link: "https://www.python.org/"
    },
    Java:
    {
        Name: "Java",
        Icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/java/java-original-wordmark.svg',
        Link: "https://www.java.com/"
    },
    Docker:
    {
        Name: "Docker",
        Icon: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/docker/docker-original-wordmark.svg",
        Link: "https://www.docker.com/"
    },
    Redis:
    {
        Name: "Redis",
        // Icon: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/redis/redis-original-wordmark.svg",
        Icon: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/redis/redis-plain-wordmark.svg",
        Link: "https://redis.io/"
    },
    AWS:
    {
        Name: "AWS",
        Icon: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/amazonwebservices/amazonwebservices-original-wordmark.svg",
        Link: "https://aws.amazon.com/"
    },
    SQL:
    {
        Name: "SQL",
        Icon: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mysql/mysql-original-wordmark.svg",
        Link: "https://www.mysql.com/"
    },
    Unity:
    {
        Name: "Unity",
        Icon: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/unity/unity-original-wordmark.svg",
        Link: "https://unity.com/"
    },
    Unreal:
    {
        Name: "Unreal Engine",
        Icon: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/unrealengine/unrealengine-original-wordmark.svg",
        Link: "https://www.unrealengine.com/"
    },
    SocketIO:
    {
        Name: "Socket.IO",
        Icon: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/socketio/socketio-original-wordmark.svg",
        Link: "https://socket.io/"
    },
    DirectX:
    {
        Name: "DirectX11",
        Icon: 'https://static.cdnlogo.com/logos/m/30/microsoft-directx.svg',
        Link: "https://docs.microsoft.com/en-us/windows/win32/directx"
    },
    Blender:
    {
        Name: "Blender",
        Icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/blender/blender-original.svg',
        Link: "https://www.blender.org/"
    },
    ThreeDSMax:
    {
        Name: "3DS Max",
        Icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/threedsmax/threedsmax-original.svg',
        Link: "https://www.autodesk.com/products/3ds-max/overview"
    },
    SubstancePainter:
    {
        Name: "Substance Painter",
        Icon: 'https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/adobe-substance-3d-painter-icon.svg',
        Link: "https://www.substance3d.com/products/substance-painter/"
    },
    Photoshop:
    {
        Name: "Photoshop",
        Icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/photoshop/photoshop-original.svg',
        Link: "https://www.adobe.com/products/photoshop.html"
    },
    AndroidStudio:
    {
        Name: "Android Studio",
        // Icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/androidstudio/androidstudio-original-wordmark.svg',
        Icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/androidstudio/androidstudio-original.svg',
        Link: "https://developer.android.com/studio"
    },
    Github:
    {
        Name: "Github",
        Icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/github/github-original.svg',
        Link: "https://github.com/shubhgawhade"
    },
    MythosLabs:
    {
        Name: "Mythos Labs",
        Icon: 'https://mythoslabs.org/wp-content/uploads/elementor/thumbs/cropped-Mythos-Logo-1-oshyhk8cxkt5jp6itkm15lhrukh2u90wgrryyp-p864ve54usnuucea6zeqpxe9pnq9b9g7v87f8kg8dw.png',
        Link: "https://mythoslabs.org/"
    },
    Youtube:
    {
        Name: "Youtube",
        Icon: 'https://www.vectorlogo.zone/logos/youtube/youtube-icon.svg',
        Link: "https://www.youtube.com/"
    },
    LinkedIn:
    {
        Name: "LinkedIn",
        Icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/linkedin/linkedin-original.svg',
        Link: "https://www.linkedin.com/in/shubhgawhade/"
    },
    Gmail:
    {
        Name: "Gmail",
        Icon: 'https://www.svgrepo.com/show/349378/gmail.svg',
        Link: "mailto:shubh.gawhade@gmail.com"
    },
    Javascript:
    {
        Name: "Javascript",
        Icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-original.svg',
        Link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript"
    },
    Typescript:
    {
        Name: "Typescript",
        Icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/typescript/typescript-original.svg',
        Link: "https://www.typescriptlang.org/"
    },
    oofDa:
    {
        Name: "Studio oofDa",
        Link: "https://sites.google.com/view/oofda"
    },
    ItchIo:
    {
        Name: "Itch.io",
        // Icon: 'https://www.svgrepo.com/show/452232/itch-io.svg',
        Link: "https://shubhgawhade.itch.io/"
    },
    Portfolio:
    {
        Name: "Portfolio",
        Link: "http://localhost:420/portfolio"
    }
};