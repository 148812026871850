import React from 'react';
import CustomCursor from "./CustomCursor";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./Home";
import Portfolio from "./Portfolio";

function App()
{
    return (
        <div className="App">
            {!/Mobi|Android/i.test(navigator.userAgent) && <CustomCursor/>}

                <Routes>
                    {/* HOME PAGE */}
                    <Route path="/*" element={<Home/>}/>
                    <Route path="/portfolio" element={<Portfolio/>}/>
                </Routes>

            <footer style={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                zIndex: 1000,
                textAlign: 'center',
            }}>
                <h1 className="highlightText" style={{fontSize: "1em"}}>
                    &copy; {new Date().getFullYear()} Shubh Gawhade. All rights reserved.
                </h1>
            </footer>

        </div>
    );
}

export default App;

