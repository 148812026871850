import React, { useEffect, useState } from 'react';
import { motion, useMotionValue } from 'framer-motion';

function CustomCursor()
{
    const cursorX = useMotionValue(-1);
    const cursorY = useMotionValue(-1);
    const scale = useMotionValue(1);
    const backgroundColor = useMotionValue('rgba(0,0,0,0.58)');
    const width = useMotionValue('20px');
    const height = useMotionValue('20px');
    const borderRadius = useMotionValue('50%');
    const [timeoutId, setTimeoutId] = useState(null);
    const [enterButton, setEnterButton] = useState(false);

    useEffect(() =>
    {
        const moveCursor = (e) =>
        {
            if (timeoutId)
            {
                clearTimeout(timeoutId);
            }

            const newTimeoutId = setTimeout(() =>
            {
                cursorX.set(e.clientX - 9);
                cursorY.set(e.clientY - 9);
            }, 20); // 50ms delay
            setTimeoutId(newTimeoutId);
        };

        const handleMouseEnter = (e) =>
        {
            scale.set(1.2);

            switch (e.currentTarget.className)
            {
                case 'subHeadingExpandable':

                    backgroundColor.set('rgba(255,188,0,0.6)');
                    break

                case 'externalTextLink':

                    backgroundColor.set('rgba(255,255,255,0.6)');
                    break;

                case 'dot':

                    backgroundColor.set('rgba(255,188,0,0.6)');
                    break;
            }

            // const rect = e.target.getBoundingClientRect();
            // width.set(`${rect.width}px`);
            // height.set(`${rect.height}px`);
            // borderRadius.set(window.getComputedStyle(e.target).borderRadius);
            // backgroundColor.set('rgba(255,188,0,0.6)');
        };

        const handleMouseLeave = () =>
        {
            width.set('20px');
            height.set('20px');
            borderRadius.set('50%');
            scale.set(1);
            backgroundColor.set('rgba(0,0,0,0.58)');

            setEnterButton(false);
        };

        window.addEventListener('mousemove', moveCursor);
        // const elements = document.querySelectorAll('button, .customButton');
        //
        // elements.forEach(el =>
        // {
        //     el.addEventListener('mouseenter', handleMouseEnter);
        //     el.addEventListener('mouseleave', handleMouseLeave);
        // });

        const attachListeners = () =>
        {
            document.querySelectorAll('.projectYTVideo, .closeButton, .internalLinkButton, .externalLinkButton, .softwareTag').forEach(button =>
            {
                button.addEventListener('mouseenter', function(e)
                {
                    const rect = button.getBoundingClientRect();
                    const x = e.clientX - rect.left;
                    const y = e.clientY - rect.top;
                    // backgroundColor.set('rgba(252,190,17,0)');

                    button.style.setProperty('--mouse-x', `${x}px`);
                    button.style.setProperty('--mouse-y', `${y}px`);

                    // console.log(button.className);
                    var color;
                    switch (button.className)
                    {
                        case 'projectYTVideo':

                            color = 'rgba(0,0,0,0.85)';

                            break;

                        case 'internalLinkButton':

                            color = 'var(--buttonHighlightInternal)';

                            break;

                        case 'externalLinkButton':

                            color = 'var(--buttonHighlightExternal)';

                        break;

                        case 'closeButton':

                            // color = 'rgba(255, 0, 0, 0.6)';
                            color = 'rgba(255, 0, 0, 0.6)';

                            break;

                        case "softwareTag":

                            color = 'rgba(0,0,0,0.5)';

                            break;

                        default:

                            color = 'rgba(117,0,255,0.5)';

                            break;
                    }

                    button.style.setProperty('--background', color);


                    setEnterButton(true);

                    button.addEventListener('mouseup', function(e)
                    {
                        setEnterButton(false);
                    }, {once: true});
                });

                button.addEventListener('mouseleave', handleMouseLeave);

            });
            const elements = document.querySelectorAll('.dot, .externalTextLink, .subHeadingExpandable');
            elements.forEach(el => {
                el.addEventListener('mouseenter', handleMouseEnter);
                el.addEventListener('mouseleave', handleMouseLeave);
            });
        };

        // Attach listeners initially
        attachListeners();

        // Use MutationObserver to detect changes in the DOM
        const observer = new MutationObserver(() => {
            attachListeners();
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () =>
        {
            window.removeEventListener('mousemove', moveCursor);
            const elements = document.querySelectorAll('button, .closeButton');
            elements.forEach(el => {
                el.removeEventListener('mouseenter', handleMouseEnter);
                el.removeEventListener('mouseleave', handleMouseLeave);
            });
        };
    }, [cursorX, cursorY, scale, backgroundColor, width, height, borderRadius]);

    if (cursorX.get() === -1)
    {
        return null;
    }

    return (
        <motion.div
            className="custom-cursor"
            style={{
                translateX: cursorX,
                translateY: cursorY,
                scale: scale,
                backgroundColor: backgroundColor,
                width: width,
                height: height,
                borderRadius: borderRadius,
                position: 'fixed',
                top: 0,
                left: 0,
                pointerEvents: 'none',
                zIndex: 9999,
                opacity: enterButton ? 0 : 1,
                transition: enterButton ? 'none' : 'opacity 0.2s ease-out',
                cursor: 'none',
            }}
        />
    );
}

export default CustomCursor;