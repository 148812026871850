import React, {useEffect, useState} from "react";
import ShowcaseImagesTemplate from "./ShowcaseImagesTemplate";

// TODO: this should have arguments for just the project, project should have all the data
const DescriptionCard = ({ project, onClose }) => {
    const [show, setShow] = useState();

    useEffect(() =>
    {
        setShow(true);
    }, []);

    const handleClickOutside = (event) =>
    {
        if (event.target.className === "description-card-overlay show")
        {
            setShow(false);

            setTimeout(() =>
            {
                onClose();
            }, 200);
        }
    };

    // Based on the project data, return different components to make them individually editable and interactable
    return (
        <div className={`description-card-overlay ${show ? 'show' : 'hide'}`} onClick={handleClickOutside}>
            <div className="description-card">

                <div className="closeButton" style={{
                    padding: "5px 15px",
                    backgroundColor: 'var(--buttonColour)',
                    top: "5px",
                    right: "5px"
                }} onClick={() => {
                    setShow(false);
                    setTimeout(() =>
                    {
                        onClose();
                    }, 200);
                }}>
                    <div style={{fontSize: "1.1em", cursor: "none"}}>
                        X
                    </div>
                </div>

                <h1 className="heading">{project.name}</h1>

                {/* Checks the video source, If it is YT, use iframe, else use video tag*/}
                {project.videoURL &&
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{position: 'relative', width: '75%', paddingBottom: '42.18%'}}>

                            {
                                project.videoURL && project.videoURL.includes("https://www.youtube.com") &&

                                <iframe className="projectYTVideo"
                                        src={project.videoURL + "&loop=1&rel=0"}
                                        title={project.title}
                                        frameBorder="0"
                                        allowFullScreen
                                        referrerPolicy="strict-origin-when-cross-origin"
                                ></iframe>
                            }

                            {
                                project.videoURL && !project.videoURL.includes("https://www.youtube.com") &&

                                <video loop controls={true}
                                       className="projectYTVideo">
                                    <source
                                        src={project.videoURL + "&autoplay=1&mute=1&loop=1&controls=0&modestbranding=0&rel=0&playsinline=1&enablejsapi=1"}
                                        type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            }

                            {/*{*/}
                            {/*    !project.videoURL &&*/}
                            {/*    <div>*/}
                            {/*        <img src={project.imageURL} alt={project.name}*/}
                            {/*             style={{*/}
                            {/*                 width: '100%',*/}
                            {/*                 objectFit: "cover",*/}
                            {/*                 overflow: "hidden",*/}
                            {/*                 borderRadius: "6px"*/}
                            {/*             }}/>*/}
                            {/*    </div>*/}
                            {/*}*/}


                        </div>
                        <div className="videoDescriptionText">
                            {project.videoDescription}
                        </div>
                    </div>
                }

                {/*PROJECT DESCRIPTION*/}
                {project.body()}

                {/*SHOWCASE IMAGES*/}
                {
                    project.showcaseImages?.length > 0 &&
                    <ShowcaseImagesTemplate
                        heading="Images Showcase"
                        showcaseImages={project.showcaseImages}
                        showcaseDescriptions={project.altText}
                        width={'75%'}
                    />
                }

                {/*SOFTWARE TAGS*/}
                {
                    project.softwareTags?.length > 0 &&
                    <div style={{color: '#e94f37'}}>
                        <h1 className="subHeading" style={{marginBottom: 0}}>Softwares</h1>

                        <ul style={{color: '#fcbe11', marginTop: 0}}>
                            {project.softwareTags.map((tag, index) => (
                                <li className="softwareTag"
                                    style={{
                                        backgroundColor: "rgba(255,255,255,0.6)",
                                        borderRadius: "15px",
                                        padding: "5px",
                                        marginRight: "10px",
                                        marginTop: "10px",
                                        width: '70px',
                                        height: '70px'
                                    }} key={index}>
                                    {
                                        tag.Icon
                                            ? <img src={tag.Icon} alt={`${tag.Name}`}
                                                   onClick={() => {
                                                       window.open(tag.Link, '_blank');
                                                   }}/> : <div
                                                onClick={() => {
                                                    window.open(tag.Link, '_blank');
                                                }}
                                                style={{
                                                fontFamily: "'Roboto Mono', monospace",
                                                fontWeight: 700,
                                                fontSize: '0.9rem',
                                                color: "black",
                                                height: '70px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}> {tag.Name} </div>
                                    }
                                </li>
                            ))}
                        </ul>
                    </div>
                }

                {/*CLIENT TAGS*/}
                {
                    project.clientTags?.length > 0 &&
                    <div className="client-tags" style={{color: '#e94f37'}}>
                        <h1 className="subHeading" style={{marginBottom: 0}}>Clients</h1>

                        <ul style={{color: '#fcbe11', marginTop: 0}}>
                            {project.clientTags.map((tag, index) => (
                                <li className="softwareTag"
                                    style={{
                                        backgroundColor: "rgba(255,255,255,0.6)",
                                        borderRadius: "15px",
                                        padding: "5px",
                                        marginRight: "10px",
                                        marginTop: "10px",
                                        width: '70px',
                                        height: '70px'
                                    }} key={index}>
                                    {
                                        tag.Icon
                                            ? <img src={tag.Icon} alt={`${tag.Name}`}
                                                   onClick={() => {
                                                       window.open(tag.Link, '_blank');
                                                   }}/> : <div
                                                onClick={() => {
                                                    window.open(tag.Link, '_blank');
                                                }}
                                                style={{
                                                fontFamily: "'Roboto Mono', monospace",
                                                fontWeight: 700,
                                                fontSize: '0.9rem',
                                                color: "black",
                                                height: '70px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}> {tag.Name} </div>
                                    }
                                </li>
                            ))}
                        </ul>
                    </div>
                }

                {/*EXTERNAL LINK TAGS*/}
                {
                    project.externalLinks?.length > 0 &&
                    <div style={{color: '#e94f37'}}>
                        <h1 className="subHeading" style={{marginBottom: 0}}>Socials</h1>

                        <ul style={{color: '#fcbe11', marginTop: 0}}>
                            {project.externalLinks.map((tag, index) => (
                                <li className="softwareTag"
                                    style={{
                                        backgroundColor: "rgba(255,255,255,0.6)",
                                        borderRadius: "15px",
                                        padding: "5px",
                                        marginRight: "10px",
                                        marginTop: "10px",
                                        width: '70px',
                                        height: '70px'
                                    }} key={index}>
                                    {
                                        tag.Icon
                                            ? <img src={tag.Icon} alt={`${tag.Name}`}
                                                   onClick={() => {
                                                       window.open(tag.Link, '_blank');
                                                   }}/> : <div
                                                onClick={() => {
                                                    window.open(tag.Link, '_blank');
                                                }}
                                                style={{
                                                fontFamily: "'Roboto Mono', monospace",
                                                fontWeight: 700,
                                                fontSize: '0.9rem',
                                                color: "black",
                                                height: '70px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}> {tag.Name} </div>
                                    }
                                </li>
                            ))}
                        </ul>
                    </div>
                }
            </div>
        </div>
    );
};

export default DescriptionCard;