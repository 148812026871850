import React, {useEffect, useState} from "react";

const ShowcaseImagesTemplate = ({ heading, showcaseImages, showcaseDescriptions, onImageChange, width = '100%' }) =>
{
    const [imageSlideshowPaused, setIsImageSlideshowPaused] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(null);

    useEffect(() =>
    {
        if(showcaseImages && showcaseImages.length > 0)
        {
            // console.log(currentImageIndex);

            if(currentImageIndex === null)
            {
                setCurrentImageIndex(0);
            }

            const showSlides = () =>
            {
                const newIndex = (currentImageIndex + 1) % showcaseImages.length;
                setCurrentImageIndex(newIndex);
                if (onImageChange)
                {
                    onImageChange(newIndex);
                }
            };

            if(!imageSlideshowPaused)
            {
                const timer = setTimeout(showSlides, 4000);
                return () => clearTimeout(timer);
            }
            else
            {
                const timer = setTimeout(() =>{setIsImageSlideshowPaused(false)}, 10000);
                return () => clearTimeout(timer);
            }
        }
    }, [currentImageIndex, imageSlideshowPaused, showcaseImages]);

    return (
        <div>
            <h1 className="subHeading">{heading}</h1>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className="slideshow-container"
                     style={{position: 'relative', width: width}}>
                    <img className="responsiveImage" style={{borderRadius: '6px', maxHeight: '60vh', maxWidth: '100%',
                        backgroundColor: 'rgba(0,0,0,0.25)',
                        boxShadow: '0 0px 10px rgba(0, 0, 0, 1)'}}
                        src={showcaseImages[currentImageIndex]}
                        alt={showcaseDescriptions[currentImageIndex]}/>
                </div>

                <div className="imageDescriptionText" style={{paddingBottom: "2px"}}>
                    {showcaseDescriptions[currentImageIndex]}
                </div>

                {/*Make the buttons below clickable to see the images*/}
                <div style={{textAlign: 'center'}}>
                    {showcaseImages.map((_, index) => (
                        <span key={index} className="dot" style={{
                            height: '15px',
                            width: '15px',
                            margin: '0 2px',
                            backgroundColor: currentImageIndex === index ? 'rgba(252,190,17,0.75)' : 'rgba(0,0,0,0.6)',
                            borderRadius: '50%',
                            display: 'inline-block',
                            transition: 'background-color 0.6s ease',
                            boxShadow: '0 0px 3px rgba(0, 0, 0, 1)',
                            marginBottom: '10px',

                        }}
                              onClick={() =>
                              {
                                  setCurrentImageIndex(index);
                                  setIsImageSlideshowPaused(true);
                                  if (onImageChange)
                                  {
                                      onImageChange(index);
                                  }
                              }}></span>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ShowcaseImagesTemplate;